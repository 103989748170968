import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import {CircularProgress} from "@mui/material";
import AuthContext from "../../../contexts/AuthContext";
import CustomTag from "../../custom/CustomTag";
import CustomList from "../../custom/CustomList";
import columns from "../../../assets/utils/columns";
import {toast} from "react-toastify";
import toastOptions from "../../../assets/utils/toast";

const SubscriptionList = () => {

    const waitingSubscriptions = React.useMemo(() => columns.waitingSubscriptions, []);
    const [isLoaded, setIsLoaded] = useState(false);
    const [extractLoading, setExtractLoading] = useState(false);
    const [subscriptions, setSubscriptions] = useState();
    const { token } = useContext(AuthContext);

    useEffect(() => {
        setIsLoaded(false);
        axios.get(process.env.REACT_APP_ADMIN_API_URL + 'subscriptions', { headers: { Authorization: 'Bearer ' + token }})
            .then((response) => {
                if(response.data) {
                    setSubscriptions(response.data);
                    setIsLoaded(true);
                }
            })
            .catch((error) => {
                console.log(error);
                setIsLoaded(true);
            });
    }, []);

    const handleDownload = () => {

        setExtractLoading(true);

        axios.get(process.env.REACT_APP_ADMIN_API_URL + 'subscriptions/extract', { headers: { Authorization: 'Bearer ' + token }, responseType: 'blob' })
            .then((response) => {
                if(response.data) {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'export.xlsx');
                    document.body.appendChild(link);
                    link.click();

                    window.URL.revokeObjectURL(url);
                    setExtractLoading(false);
                }
            })
            .catch((error) => {
                console.log(error);
                toast.error("Une erreur est survenue", toastOptions);
                setExtractLoading(false);
            });
    };

    return isLoaded ? (
        <div>
            <div className='view-header subscriptions'>
                <div className='view-title'>
                    <h1>Inscriptions</h1>
                </div>
                <div className='view-buttons'>
                    <button className='main-button download' disabled={extractLoading} onClick={handleDownload}>{ extractLoading ? <CircularProgress size={15} /> : 'Télécharger' }</button>
                </div>
            </div>
            <div className="view-container scrollable subscriptions">
                <div className='view-subtitle'>
                    <div className='flex-view'>
                        <h2>Inscriptions en attente de validation</h2>
                        <CustomTag value={subscriptions.filter(sub => sub.status === "waiting-validation").length} className="margin-left" />
                    </div>
                </div>
                { subscriptions.filter(sub => sub.status === "waiting-validation").length > 0 ? (
                    <CustomList
                        columns={waitingSubscriptions}
                        entity={subscriptions.filter(sub => sub.status === "waiting-validation")}
                        isSelectable={false}
                        isPaginable={subscriptions.filter(sub => sub.status === "waiting-validation").length > 20}
                    />
                ) : (
                    <span className='empty-info'>Vous n'avez aucune inscription en attente de validation pour le moment.</span>
                )}
                <div className='view-subtitle'>
                    <div className='flex-view'>
                        <h2>Inscriptions en attente de test</h2>
                        <CustomTag value={subscriptions.filter(sub => sub.status === "waiting-test").length} className="margin-left" />
                    </div>
                </div>
                { subscriptions.filter(sub => sub.status === "waiting-test").length > 0 ? (
                    <CustomList
                        columns={waitingSubscriptions}
                        entity={subscriptions.filter(sub => sub.status === "waiting-test")}
                        isSelectable={false}
                        isPaginable={subscriptions.filter(sub => sub.status === "waiting-test").length > 20}
                    />
                ) : (
                    <span className='empty-info'>Vous n'avez aucune inscription en attente de validation pour le moment.</span>
                )}
                <div className='view-subtitle'>
                    <div className='flex-view'>
                        <h2>Inscriptions en attente de paiement</h2>
                        <CustomTag value={subscriptions.filter(sub => sub.status === "waiting-payment").length} className="margin-left" />
                    </div>
                </div>
                { subscriptions.filter(sub => sub.status === "waiting-payment").length > 0 ? (
                    <CustomList
                        columns={waitingSubscriptions}
                        entity={subscriptions.filter(sub => sub.status === "waiting-payment")}
                        isSelectable={false}
                        isPaginable={subscriptions.filter(sub => sub.status === "waiting-payment").length > 20}
                    />
                ) : (
                    <span className='empty-info'>Vous n'avez aucune inscription en attente de paiement pour le moment.</span>
                )}
                <div className='view-subtitle'>
                    <div className='flex-view'>
                        <h2>Inscriptions en attente de changement</h2>
                        <CustomTag value={subscriptions.filter(sub => sub.status === "change-needed").length} className="margin-left" />
                    </div>
                </div>
                { subscriptions.filter(sub => sub.status === "change-needed").length > 0 ? (
                    <CustomList
                        columns={waitingSubscriptions}
                        entity={subscriptions.filter(sub => sub.status === "change-needed")}
                        isSelectable={false}
                        isPaginable={subscriptions.filter(sub => sub.status === "change-needed").length > 20}
                    />
                ) : (
                    <span className='empty-info'>Vous n'avez aucune inscription en attente de changement pour le moment.</span>
                )}
            </div>
        </div>
    ) : <div className='loader-container'><CircularProgress size={30} /></div>;
}

export default SubscriptionList;