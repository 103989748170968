import {CircularProgress, Grid, IconButton} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import React, {useContext} from "react";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import {TimePicker} from "@mui/x-date-pickers/TimePicker";
import CustomSearchInput from "../../custom/CustomSearchInput";
import AuthContext from "../../../contexts/AuthContext";
import GroupIcon from "../groups/GroupIcon";
import axios from "axios";
import {toast} from "react-toastify";
import toastOptions from "../../../assets/utils/toast";
import CloseIcon from "@mui/icons-material/Close";

const EventEdit = ({ event, planning, setPlanning, setOpen, setIsEditing, setModalWarningOpen, groups }) => {

    const { token } = useContext(AuthContext);
    const[buttonLoading, setButtonLoading] = React.useState(false);
    const [newEvent, setNewEvent] = React.useState({
        ...event,
        start: dayjs(event.start),
        end: dayjs(event.end),
    });

    const handleSave = () => {
        setButtonLoading(true);
        const formattedEvent= {
            ...newEvent,
            start: newEvent.start.format('YYYY-MM-DD HH:mm:ss'),
            end: newEvent.end.format('YYYY-MM-DD HH:mm:ss'),
            title: groups.find(g => g.id === newEvent.training.groupId).name,
            color: groups.find(g => g.id === newEvent.training.groupId).color,
            active: true
        };
        axios.put(process.env.REACT_APP_ADMIN_API_URL + 'planning', formattedEvent, { headers: { Authorization: 'Bearer ' + token }})
            .then(() => {
                setPlanning(planning.map(e => e.id === newEvent.id ? formattedEvent : e));
                setButtonLoading(false);
                setOpen(false);
                setIsEditing(false);
                toast.success("L'évènement a bien été modifié", toastOptions);
            })
            .catch((error) => {
                console.log(error);
                toast.error("Une erreur est survenue", toastOptions);
                setButtonLoading(false);
            });
    }

    return (
        <div>
            <div className='training-details-content'>
                <div className='training-details-header'>
                    <div className='flex-start'>
                        <GroupIcon width={20} color={event.training.group.color}/>
                        <h1>{event.training.group.name}</h1>
                    </div>
                    <div className='training-details-buttons'>
                        <IconButton aria-label="delete" size="small" onClick={() => setModalWarningOpen(true)}>
                            <DeleteIcon fontSize="small"/>
                        </IconButton>
                        <IconButton aria-label="delete" size="small" onClick={() => {
                            setOpen(false);
                            setIsEditing(false);
                        }}>
                            <CloseIcon fontSize="small"/>
                        </IconButton>
                    </div>
                </div>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <DatePicker
                            format='DD/MM/YYYY'
                            label='Début'
                            slotProps={{
                                textField: {
                                    // error: error && error.missingFields.includes('birthDate')
                                    size: 'small',
                                },
                            }}
                            value={newEvent.start}
                            onChange={(newDate) => setNewEvent({
                                ...newEvent,
                                start: dayjs(dayjs(newDate).format('YYYY-MM-DD') + newEvent.start.format('HH:mm:ss'))
                            })}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TimePicker
                            label="Heure"
                            ampm={false}
                            className='hour-picker'
                            slotProps={{textField: {size: 'small'}}}
                            views={['hours', 'minutes']}
                            value={newEvent.start}
                            onChange={(date) => setNewEvent({...newEvent, start: dayjs(date)})}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DatePicker
                            format='DD/MM/YYYY'
                            label='Fin'
                            slotProps={{
                                textField: {
                                    // error: error && error.missingFields.includes('birthDate')
                                    size: 'small',
                                },
                            }}
                            minDate={newEvent.start}
                            value={newEvent.end}
                            onChange={(newDate) => setNewEvent({
                                ...newEvent,
                                end: dayjs(dayjs(newDate).format('YYYY-MM-DD') + newEvent.end.format('HH:mm:ss'))
                            })}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TimePicker
                            label="Heure"
                            ampm={false}
                            className='hour-picker'
                            slotProps={{textField: {size: 'small'}}}
                            views={['hours', 'minutes']}
                            value={newEvent.end}
                            onChange={(date) => setNewEvent({...newEvent, end: dayjs(date)})}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CustomSearchInput
                            placeholder='Choisissez une piscine'
                            entity='pools'
                            labelType='pool'
                            value={newEvent.poolId}
                            onChange={(pool) => setNewEvent({...newEvent, poolId: pool.id})}
                            valueIsId
                        />
                    </Grid>
                </Grid>
                <div className='bottom-buttons'>
                    <button className='main-button without-icon' onClick={() => setIsEditing(false)}>Annuler</button>
                    <button className='main-button blue-button without-icon' onClick={handleSave} disabled={buttonLoading}>{buttonLoading ?
                        <CircularProgress className='white-loader' size={20}/> : "Enregistrer"}</button>
                </div>
            </div>
        </div>
    );
}

export default EventEdit;