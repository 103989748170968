import {CircularProgress, Grid, IconButton, Tooltip} from "@mui/material";
import React, {useContext, useState} from "react";
import axios from "axios";
import AuthContext from "../../../contexts/AuthContext";
import {toast} from "react-toastify";
import toastOptions from "../../../assets/utils/toast";
import TrainingName from "../trainings/TrainingName";
import CustomFile from "../../custom/CustomFile";
import CustomModal from "../../custom/CustomModal";
import CustomRichText from "../../custom/CustomRichText";
import CustomTag from "../../custom/CustomTag";
import button from "bootstrap/js/src/button";
import {Link} from "react-router-dom";
import dayjs from "dayjs";
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import ModeEditOutlineRoundedIcon from '@mui/icons-material/ModeEditOutlineRounded';
import HistoryRoundedIcon from '@mui/icons-material/HistoryRounded';
import CloseIcon from '@mui/icons-material/Close';
import SubscriptionGroupUpdate from "./SubscriptionGroupUpdate";
import NoteEdit from "./NoteEdit";
import FieldEdit from "./FieldEdit";

const SubscriptionValidation = ({ subscription, group, groups, appRefresh, setAppRefresh }) => {

    const [refusedModal, setRefusedModal] = useState(false);
    const [changeModal, setChangeModal] = useState(false);
    const [statusMessage, setStatusMessage] = useState();
    const [refusedMessage, setRefusedMessage] = useState();
    const [buttonLoading, setButtonLoading] = useState(false);
    const [groupUpdating, setGroupUpdating] = useState(false);
    const [loadingType, setLoadingType] = useState('');
    const { user, token, club } = useContext(AuthContext);

    const handleChangeStatus = (status) => {
        setButtonLoading(true);
        setLoadingType(status.type);
        axios.put(process.env.REACT_APP_ADMIN_API_URL + 'subscriptions/status' , {...status, subscriptionId: subscription.id, clubId: club.id, groupId: group.id }, { headers: { Authorization: 'Bearer ' + token }})
            .then(() => {
                setButtonLoading(false);
                setAppRefresh(appRefresh + 1);
                setLoadingType('');
                toast.success("Le statut a bien été modifié", toastOptions);
            })
            .catch((error) => {
                toast.error(error.message, toastOptions);
                setLoadingType('');
                setButtonLoading(false);
            });
    }

    const handleChangeFile = (file, newFile) => {

        const formData = new FormData();
        formData.append('changedFile', JSON.stringify(file));
        const renamedFile = new File([newFile], `${file.id}.${newFile.name.split('.').pop()}`, { type: newFile.type });
        formData.append('file', renamedFile);

        axios.put(process.env.REACT_APP_ADMIN_API_URL + 'subscriptions/' + subscription.id + '/file', formData, { headers : { 'Content-Type': 'multipart/form-data', Authorization: 'Bearer ' + token }})
            .then(() => {
                toast.success("Le fichier a bien été mis à jour", toastOptions);
                setAppRefresh(appRefresh + 1);
            })
            .catch((error) => {
                toast.error("Une erreur est survenue", toastOptions);
                console.log(error);
            });
    }

    return (
        <div className='subscription-validation'>
            <div className='subscription-line season'>
                <span className='subscription-line-title'>Saison </span>
                <CustomTag value={user.club.seasons.find(s => s.id === subscription.group.seasonId).name} className={subscription.group.seasonId === club.activeSeasonId ? 'active' : 'inactive'} />
            </div>
            <div className='subscription-line status'>
                <span className='subscription-line-title'>Statut </span>
                <CustomTag value={subscription.status}/>
            </div>
            <div className='subscription-line group'>
                <span className='subscription-line-title'>Groupe </span>
                <Link to={`/admin/groups/${subscription.group.id}`}>
                    <CustomTag value={subscription.group.name}/>
                </Link>
                <div>
                    { groupUpdating ? (
                        <IconButton aria-label="delete" size="small" onClick={() => setGroupUpdating(false)}>
                            <CloseIcon fontSize="small"/>
                        </IconButton>
                    ) : (
                        <IconButton aria-label="delete" size="small" onClick={() => setGroupUpdating(true)}>
                            <ModeEditOutlineRoundedIcon fontSize="small"/>
                        </IconButton>
                    )}
                    <Tooltip enterTouchDelay={0} leaveTouchDelay={5000} leaveDelay={800} placement='right-start' id='options-tooltip' title={
                        <React.Fragment>
                            <div className='previous-subscriptions-container'>
                                <h1>Historique des adhésions</h1>
                                { subscription.previousSubscriptions.length > 0 ? subscription.previousSubscriptions.map((previousSubscription, index) => (
                                    <Link to={`/admin/subscriptions/${previousSubscription.id}`} key={index}>
                                        <div className='previous-subscription-block'>
                                            <CustomTag value={`${previousSubscription.group.name} ${previousSubscription.group.trainingsType === 'optional' ? `- ${previousSubscription.trainings.length} séance(s)` : ''}`} />
                                            <CustomTag value={previousSubscription.group.season.name} className={previousSubscription.group.seasonId === club.activeSeasonId ? 'active' : 'inactive' } />
                                        </div>
                                    </Link>
                                )) : (
                                    <CustomTag value='Aucune adhésion précédente' />
                                )}
                            </div>
                        </React.Fragment>
                    }>
                        <IconButton aria-label="delete" size="small">
                            <HistoryRoundedIcon fontSize="small"/>
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
            {group.trainingsType === "optional" ? (
                <div className='subscription-profile-recap trainings'>
                    <div className='subscription-profile-recap-lines'>
                        {subscription.trainings.map((training, index) => (
                            <span className='calendar'><TrainingName key={index}
                                                                     training={group.trainings.find(groupTraining => training.trainingId === groupTraining.id)}/></span>
                        ))}
                    </div>
                </div>
            ) : null}
            {groupUpdating ? (
                <SubscriptionGroupUpdate
                    subscription={subscription}
                    groups={groups}
                    group={group}
                    appRefresh={appRefresh}
                    setAppRefresh={setAppRefresh}
                />
            ) : null}
            <div className='subscription-line user'>
                <span className='subscription-line-title'>Profil </span>
                <Link to={`/admin/profiles/${subscription.profile.id}`}>
                    <CustomTag value={`${subscription.profile.firstName} ${subscription.profile.lastName}`}/>
                </Link>
                <Link to={`/admin/profiles/${subscription.profile.id}/edit`}>
                    <IconButton aria-label="delete" size="small">
                        <ModeEditOutlineRoundedIcon fontSize="small"/>
                    </IconButton>
                </Link>
            </div>
            <div className='subscription-profile-recap'>
                <div className='subscription-profile-recap-lines'>
                    <span
                        className='id'>IUF : {subscription.profile.federalId ? subscription.profile.federalId : 'Non renseigné'}</span>
                    <span
                        className='birth'>{dayjs(subscription.profile.birthDate).format('DD MMMM YYYY')} ({dayjs().diff(subscription.profile.birthDate, 'year')} ans)</span>
                    <span
                        className='address'>{subscription.profile.address} {subscription.profile.postalCode} {subscription.profile.city}</span>
                    {subscription.profile.parentLastName ? (
                        <span
                            className='info'>Représentant légal : {subscription.profile.parentFirstName} {subscription.profile.parentLastName}</span>
                    ) : null}
                    <a className='email'
                       href={`mailto:${subscription.profile.customer.email}`}>{subscription.profile.customer.email}</a>
                    <a className='phone' href={`tel:${subscription.profile.phone}`}>{subscription.profile.phone}</a>
                </div>
                <div className='right-top-buttons'>
                    <Link to={`/admin/profiles/${subscription.profile.id}`}>
                        <IconButton aria-label="delete" size="small">
                            <VisibilityRoundedIcon fontSize="small"/>
                        </IconButton>
                    </Link>
                </div>
            </div>
            <div className='form-info-container'>
                <NoteEdit subscription={subscription} appRefresh={appRefresh} setAppRefresh={setAppRefresh}/>
            </div>
            {group.fields.length > 0 ? (
                <div className='form-info-container'>
                    <FieldEdit activeSubscription={subscription} fields={group.fields} appRefresh={appRefresh}
                               setAppRefresh={setAppRefresh}/>
                </div>
            ) : null}
            <div className='view-subtitle file'>
                <h2>Fichiers déposés</h2>
            </div>
            <div className='form-info-container'>
                <Grid container spacing={2}>
                    {group.files.sort(file => subscription.files.find(f => f.fileId === file.id) ? -1 : 1).map(file => (
                        <Grid item xs={12} md={4} key={file.id}>
                            <CustomFile
                                isAdmin
                                fileInfo={file}
                                selectedFile={subscription.files.find(f => f.fileId === file.id)}
                                changeFile={handleChangeFile}
                                readOnly
                            />
                        </Grid>
                    ))}
                </Grid>
            </div>
            <div className='validation-buttons'>
                {subscription.status !== 'refused' ? (
                    <button
                        className='main-button red-button'
                        onClick={() => {
                            setStatusMessage();
                            setRefusedModal(true);
                        }}
                        disabled={buttonLoading}
                    >Refus</button>
                ) : null}
                <button
                    className='main-button yellow-button'
                    onClick={() => {
                        setStatusMessage();
                        setChangeModal(true);
                    }}
                    disabled={buttonLoading}
                >Changement
                </button>
                {subscription.status !== 'waiting-test' ? (
                    <button className='main-button yellow-button test' onClick={() => {
                        handleChangeStatus({type: 'waiting-test'})
                    }} disabled={buttonLoading}>
                        {loadingType === 'waiting-test' ?
                            <CircularProgress className='white-loader' size={20}/> : 'Test'}
                    </button>
                ) : null}
                {subscription.status !== 'waiting-payment' ? (
                    <button className='main-button blue-button payment' onClick={() => {
                        handleChangeStatus({type: 'waiting-payment'})
                    }} disabled={buttonLoading}>
                        {loadingType === 'waiting-payment' ?
                            <CircularProgress className='white-loader' size={20}/> : 'Paiement'}
                    </button>
                ) : null}
                {subscription.status !== 'accepted' ? (
                    <button className='main-button green-button' onClick={() => {
                        handleChangeStatus({type: 'accepted'})
                    }} disabled={buttonLoading}>
                        {loadingType === 'accepted' ?
                            <CircularProgress className='white-loader' size={20}/> : 'Valider'}
                    </button>
                ) : null}
            </div>

            <CustomModal show={changeModal} setShow={setChangeModal} title="Demande de modification">
                <div>
                    <div className='view-subtitle'>
                        <h2>Détaillez ci-dessous les champs à changer</h2>
                    </div>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <CustomRichText
                                value={statusMessage}
                                setValue={(newValue) => setStatusMessage(newValue)}
                                placeholder='Informations à changer'
                            />
                        </Grid>
                    </Grid>
                    <div className='modal-bottom-button'>
                        <button
                            className='main-button blue-button validate'
                            disabled={buttonLoading}
                            onClick={() => handleChangeStatus({
                                type: 'change-needed',
                                message: statusMessage
                            })}
                        >
                            {loadingType === 'change-needed' ?
                                <CircularProgress className='white-loader' size={20}/> : 'Envoyer'}
                        </button>
                    </div>
                </div>
            </CustomModal>
            <CustomModal show={refusedModal} setShow={setRefusedModal} title="Refuser l'adhésion">
                <div>
                    <div className='view-subtitle'>
                        <h2>Détaillez ci-dessous la raison du refus</h2>
                    </div>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <CustomRichText
                                value={refusedMessage}
                                setValue={(newValue) => setRefusedMessage(newValue)}
                                placeholder="Motif du refus"
                            />
                        </Grid>
                    </Grid>
                    <div className='modal-bottom-button'>
                        <button
                            className='main-button blue-button validate'
                            disabled={buttonLoading}
                            onClick={() => handleChangeStatus({
                                type: 'refused',
                                message: refusedMessage
                            })}
                        >
                            {loadingType === 'refused' ?
                                <CircularProgress className='white-loader' size={20}/> : 'Envoyer'}
                        </button>
                    </div>
                </div>
            </CustomModal>
        </div>
    );
}

export default SubscriptionValidation;