import {CircularProgress, Grid, IconButton, TextField, Tooltip} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import button from "bootstrap/js/src/button";
import CustomModal from "../../custom/CustomModal";
import axios from "axios";
import {toast} from "react-toastify";
import toastOptions from "../../../assets/utils/toast";
import AuthContext from "../../../contexts/AuthContext";
import Switch from "@mui/material/Switch";
import CustomTag from "../../custom/CustomTag";
import CustomRichText from "../../custom/CustomRichText";
import GroupChooser from "../../custom/GroupChooser";
import uploaded_logo from "../../../assets/icons/folder--green.svg";
import file_logo from "../../../assets/icons/folder--blue.svg";
import eye_logo from "../../../assets/icons/eye--blue.svg";
import delete_logo from "../../../assets/icons/bin--red.svg";

const FileSettings = ({groups}) => {

    const [files, setFiles] = useState([]);
    const [newFile, setNewFile] = useState({});
    const [modalAddOpen, setModalAddOpen] = useState(false);
    const [modalEditOpen, setModalEditOpen] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [editedFile, setEditedFile] = useState();
    const [buttonLoading, setButtonLoading] = useState(false);
    const { token } = useContext(AuthContext);

    useEffect(() => {
        setIsLoaded(false);
        axios.get(process.env.REACT_APP_ADMIN_API_URL + 'files', { headers: { Authorization: 'Bearer ' + token }})
            .then((response) => {
                if(response.data) {
                    setFiles(response.data);
                    setIsLoaded(true);
                }
            })
            .catch((error) => {
                console.log(error);
                setIsLoaded(true);
            });
    }, [refresh]);

    const handleCreate = () => {
        setButtonLoading(true);
        setModalAddOpen(false);

        const formData = new FormData();
        formData.append('newFile', JSON.stringify(newFile));
        if(newFile.file) {
            formData.append('file', newFile.file);
        }

        axios.post(process.env.REACT_APP_ADMIN_API_URL + 'files', formData, { headers: { Authorization: 'Bearer ' + token }})
            .then(() => {
                setButtonLoading(false);
                setNewFile({});
                setRefresh(refresh + 1);
                toast.success("Le champ a bien été ajouté", toastOptions);
            })
            .catch((error) => {
                console.log(error);
                toast.error("Une erreur est survenue", toastOptions);
                setButtonLoading(false);
            });
    }

    const handleUpdate = () => {
        setButtonLoading(true);

        const formData = new FormData();
        formData.append('editedFile', JSON.stringify(editedFile));
        if(editedFile.file) {
            formData.append('file', editedFile.file);
        }

        axios.put(process.env.REACT_APP_ADMIN_API_URL + 'files/' + editedFile.id, formData, { headers: { Authorization: 'Bearer ' + token }})
            .then(() => {
                setButtonLoading(false);
                setModalEditOpen(false);
                setRefresh(refresh + 1);
                toast.success("Le champ a bien été modifié", toastOptions);
            })
            .catch((error) => {
                console.log(error);
                toast.error("Une erreur est survenue", toastOptions);
                setButtonLoading(false);
            });
    }

    const handleDelete = (fileId) => {
        axios.delete(process.env.REACT_APP_ADMIN_API_URL + 'files/' + fileId, { headers: { Authorization: 'Bearer ' + token }})
            .then(() => {
                setRefresh(refresh + 1);
                toast.success("Le champ a bien été supprimé", toastOptions);
            })
            .catch((error) => {
                console.log(error);
                toast.error("Une erreur est survenue", toastOptions);
                setButtonLoading(false);
            });
    }

    const handleDeleteFile = () => {
        axios.delete(process.env.REACT_APP_ADMIN_API_URL + 'files/' + editedFile.id + '/template', { headers: { Authorization: 'Bearer ' + token }})
            .then((response) => {
                console.log(response.data);
                setEditedFile(response.data);
            })
            .catch((error) => {
                console.log(error);
                setModalEditOpen(false);
                toast.error("Une erreur est survenue", toastOptions);
            });
    }

    return isLoaded ? (
        <div>
            <div className='entity-add-container'>
                { files.map((file, index) => (
                    <div className='training-edit-block' key={index} >
                        <div className='entity-with-description'>
                            <div className='flex-view'>
                                <div className='view-subtitle file margin-right'>
                                    <h2>{file.label}</h2>
                                </div>
                                <CustomTag value={file.required ? 'Obligatoire' : 'Non obligatoire'} className={file.required ? 'waiting-validation' : 'active'} />
                                <CustomTag value={`${file.groups.length} groupe(s)`} className={'margin-left entity-info ' + (file.groups.length === 0 ? 'inactive' : '')} />
                            </div>
                        </div>
                        <Tooltip enterTouchDelay={0} leaveTouchDelay={5000} leaveDelay={800} placement="bottom-end" id='options-tooltip' title={
                            <React.Fragment>
                                <div className='tooltip-container'>
                                        <span className='edit' onClick={() => {
                                            setEditedFile(file);
                                            setModalEditOpen(true);
                                        }}>Modifier</span>
                                    <span className='delete' onClick={() => handleDelete(file.id)}>Supprimer</span>
                                </div>
                            </React.Fragment>
                        }>
                            <IconButton aria-label="delete" size="small">
                                <MoreVertRoundedIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </div>
                ))}
                <div className='training-edit-block'>
                    <button className='add-training-button' onClick={() => setModalAddOpen(true)}>Ajouter un fichier</button>
                </div>
            </div>
            <CustomModal show={modalAddOpen} setShow={setModalAddOpen} title='Ajouter un fichier'>
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <TextField
                            size="small"
                            label="Nom du fichier"
                            variant="outlined"
                            value={newFile.label}
                            onChange={(e) => setNewFile({...newFile, label: e.target.value })}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <div className='flex-view justify-content-end'>
                            <span>Obligatoire</span>
                            <Switch color="primary" checked={newFile.required} onChange={(e) => setNewFile({...newFile, required: e.target.checked })} />
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <CustomRichText
                            value={newFile.description}
                            setValue={(newValue) => setNewFile({...newFile, description: newValue})}
                            placeholder='Entre votre description'
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <input
                            type="file"
                            name="new-file"
                            id="new-file"
                            className="custom-file"
                            onChange={(e) => setNewFile({...newFile, file: e.target.files[0]})}
                        />
                        <label htmlFor="new-file" className={'custom-file-label ' + (newFile.file ? 'selected' : '')}>
                            <img src={newFile.file ? uploaded_logo : file_logo} width={18} alt='file' />
                            <span>{ newFile.file ? newFile.file.name : ('Ajouter un fichier à remplir')}</span>
                        </label>
                    </Grid>
                    <Grid item xs={12}>
                        <GroupChooser
                            groups={groups}
                            selectedGroups={newFile.groups ? newFile.groups : []}
                            setSelectedGroups={newGroups => setNewFile({...newFile, groups: newGroups })}
                        />
                    </Grid>
                </Grid>
                <div className='modal-bottom-button'>
                    <button className='main-button blue-button' onClick={handleCreate} disabled={buttonLoading}>{buttonLoading ? <CircularProgress className='white-loader' size={20} /> : 'Ajouter'}</button>
                </div>
            </CustomModal>
            <CustomModal show={modalEditOpen} setShow={setModalEditOpen} title='Modifier un fichier'>
                { editedFile ? (
                    <div>
                        <Grid container spacing={2}>
                            <Grid item xs={8}>
                                <TextField
                                    size="small"
                                    label="Nom du fichier"
                                    variant="outlined"
                                    value={editedFile.label}
                                    onChange={(e) => setEditedFile({...editedFile, label: e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <div className='flex-view justify-content-end'>
                                    <span>Obligatoire</span>
                                    <Switch color="primary" checked={editedFile.required} onChange={(e) => setEditedFile({...editedFile, required: e.target.checked })} />
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <CustomRichText
                                    value={editedFile.description}
                                    setValue={(newValue) => setEditedFile({...editedFile, description: newValue})}
                                    placeholder='Entrez une description'
                                />
                            </Grid>
                            <Grid item xs={editedFile.hasTemplate ? 6 : 12}>
                                <input
                                    type="file"
                                    name="edited-file"
                                    id="edited-file"
                                    className="custom-file"
                                    onChange={(e) => setEditedFile({...editedFile, file: e.target.files[0]})}
                                />
                                <label htmlFor="edited-file" className={'custom-file-label ' + (editedFile.file ? 'selected' : '')}>
                                    <img src={editedFile.file ? uploaded_logo : file_logo} width={18} alt='file' />
                                    <span>{ editedFile.file ? editedFile.file.name : (editedFile.hasTemplate ? 'Modifier le fichier' : 'Ajouter un fichier à remplir')}</span>
                                </label>
                            </Grid>
                            { editedFile.hasTemplate ? (
                                <>
                                    <Grid item xs={3}>
                                        <a href={process.env.REACT_APP_FILES_API_URL + 'templates/' + editedFile.id + editedFile.templateExtension} target="_blank" className='custom-file-label template'>
                                            <img src={eye_logo} width={18} alt='file' />
                                            <span>Voir le fichier</span>
                                        </a>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <label className='custom-file-label template delete' onClick={handleDeleteFile}>
                                            <img src={delete_logo} width={18} alt='file' />
                                            <span>Supprimer</span>
                                        </label>
                                    </Grid>
                                </>
                            ) : null }
                            <Grid item xs={12}>
                                <GroupChooser
                                    groups={groups}
                                    selectedGroups={editedFile.groups ? editedFile.groups : []}
                                    setSelectedGroups={newGroups => setEditedFile({...editedFile, groups: newGroups })}
                                />
                            </Grid>
                        </Grid>
                        <div className='modal-bottom-button'>
                            <button className='main-button blue-button edit' onClick={handleUpdate} disabled={buttonLoading}>{buttonLoading ? <CircularProgress className='white-loader' size={20} /> : 'Modifier'}</button>
                        </div>
                    </div>
                ) : null }
            </CustomModal>
        </div>
    ) : <div className='loader-container'><CircularProgress size={30} /></div>;
}

export default FileSettings;