import {Link, Navigate, Route, Routes, useLocation} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import '../../assets/styles/Public.css';
import defaultProfilePicture from "../../assets/icons/round-user--gradient.svg";
import menu_logo from "../../assets/icons/menu-burger--blue.svg";
import AuthContext from "../../contexts/AuthContext";
import {Tooltip} from "@mui/material";
import Login from "./login/Login";
import PasswordForgot from "./login/PasswordForgot";
import SignUp from "./login/SignUp";
import CustomerRoute from "./login/CustomerRoute";
import CustomerPanel from "./customers/CustomerPanel";
import Groups from "./groups/Groups";
import PlanningView from "./planning/PlanningView";

const PublicPanel = ({appRefresh, setAppRefresh}) => {

    let location = useLocation();
    const { user, customer, club, setToken, setUser, setCustomer } = useContext(AuthContext);
    const [loggedAccount, setLoggedAccount] = useState(customer || user || null);
    const [menuOpen, setMenuOpen] = useState(false);

    useEffect(() => {
        setLoggedAccount(customer || user || null);
    }, [customer, user]);

    const handleDisconnect = () => {
        localStorage.clear();
        setToken(null);
        setUser(null);
        setCustomer(null);
        setLoggedAccount(null);
    }

    return (
        <div className='public-panel-container'>
            <div className='public-top-header'>
                <div className='club-info-details'>
                    <a href={'mailto:' + club.email} target='_blank'>
                        <h2 className='email'>{club.email}</h2>
                    </a>
                    <a href={'tel:' + club.phone} target='_blank'>
                        <h3 className='phone'>{club.phone}</h3>
                    </a>
                    <a href={club.website} target='_blank'>
                        <h4 className='website'>{club.website}</h4>
                    </a>
                </div>
            </div>
            <div className='public-header'>
                <Link to='/'>
                    <div className='logo-container'>
                        <img src={club.logoUrl} alt='swimcoach-logo' />
                    </div>
                </Link>
                <div className='navbar-container'>
                    <Link to='/groups' className={location.pathname.includes('groups') ? 'selected' : ''}>Les groupes</Link>
                    { club.season.showPlanning ? (
                        <Link to='/planning' className={location.pathname.includes('planning') ? 'selected' : ''}>Le planning</Link>
                    ) : null }
                    <a href={club.website} target="_blank">Le club</a>
                </div>
                { loggedAccount ? (
                    <div className='user-info-container'>
                        <Tooltip enterTouchDelay={0} leaveTouchDelay={5000} leaveDelay={800} placement="bottom-end" id='options-tooltip' title={
                            <React.Fragment>
                                <div className='tooltip-container'>
                                    { loggedAccount.type === 'user' ? (
                                        <Link to='admin' className='home'>Accéder à l'administration</Link>
                                    ) : (
                                        <Link to='my-account' className='home'>Mon compte</Link>
                                    )}
                                    <span className='logout' onClick={handleDisconnect}>Déconnexion</span>
                                </div>
                            </React.Fragment>
                        }>
                            <div className='user-info-block'>
                                <div className='user-img-container'>
                                    <img alt='profile-picture' src={defaultProfilePicture} />
                                </div>
                                <div className='user-data-container'>
                                    <span className='user-title'>{loggedAccount.firstName + ' ' + loggedAccount.lastName}</span>
                                </div>
                            </div>
                        </Tooltip>
                    </div>
                ) : (
                    <Link to='/admin' className='button-login'>
                        <span className='main-button login'>Connexion</span>
                    </Link>
                )}
                <div className='mobile-burger-menu' onClick={() => setMenuOpen(!menuOpen)}>
                    <img src={menu_logo} width={20} alt='burger' />
                </div>
            </div>
            <div className={'mobile-burger-menu-content ' + (menuOpen ? 'open' : '')}>
                <Link to='/groups' onClick={() => setMenuOpen(false)}>Les groupes</Link>
                { club.season.showPlanning ? (
                    <Link to='/planning' onClick={() => setMenuOpen(false)}>Le planning</Link>
                ) : null }
                <a href={club.website} target="_blank">Le club</a>
                { loggedAccount ? (
                    <>
                        <Link to='/my-account' onClick={() => setMenuOpen(false)}>Mon compte</Link>
                        { loggedAccount.type === 'customer' ? (
                            <>
                                <Link to='/my-account/profiles' onClick={() => setMenuOpen(false)}>Mes profils</Link>
                                <Link to='/my-account/subscriptions' onClick={() => setMenuOpen(false)}>Mes adhésions</Link>
                            </>
                        ) : null }
                        <a className='logout' onClick={() => {
                            handleDisconnect();
                            setMenuOpen(false);
                        }}>Déconnexion</a>
                    </>
                ) : (
                    <Link to='/login' onClick={() => setMenuOpen(false)}>Connexion</Link>
                ) }
            </div>
            <div className='public-router-content'>
                <Routes>
                    <Route path='groups/*' element={<Groups appRefresh={appRefresh} setAppRefresh={setAppRefresh} />} />
                    <Route path="planning" element={<PlanningView />} />
                    <Route path="forgot-password" element={<PasswordForgot />} />
                    <Route path="login" element={<Login />} />
                    <Route path="sign-up" element={<SignUp />} />
                    <Route path='my-account/*' element={<CustomerRoute><CustomerPanel appRefresh={appRefresh} setAppRefresh={setAppRefresh} /></CustomerRoute>} />
                    <Route path='*' element={<Navigate to='groups' />} />
                </Routes>
            </div>
        </div>
    )
}

export default PublicPanel;