import { CircularProgress, FormControlLabel, Grid, IconButton, Radio, RadioGroup, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip } from "@mui/material";
import {Link, useParams} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import CustomTag from "../../custom/CustomTag";
import constants from "../../../assets/utils/constants";
import dayjs from "dayjs";
import {toast} from "react-toastify";
import toastOptions from "../../../assets/utils/toast";
import AuthContext from "../../../contexts/AuthContext";
import CustomField from "../../custom/CustomField";
import CustomFile from "../../custom/CustomFile";
import add_logo from "../../../assets/icons/round-add--blue.svg";
import closed_logo from "../../../assets/images/closed.png";
import button from "bootstrap/js/src/button";
import ProfileAdd from "../customers/ProfileAdd";
import CustomRichText from "../../custom/CustomRichText";
import HelpIcon from '@mui/icons-material/Help';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import ProfileEdit from "../customers/ProfileEdit";
import LicenceForm from "../licence/LicenceForm";

const GroupView = ({ appRefresh, setAppRefresh }) => {

    const navigate = useNavigate();
    const { groupId } = useParams();
    const { club, customer, token, setToken } = useContext(AuthContext);
    const [group, setGroup] = useState();
    const [isLoaded, setIsLoaded] = useState(false);
    const [profileModal, setProfileModal] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [error, setError] = useState();
    const [fieldError, setFieldError] = useState();
    const [account, setAccount] = useState({});
    const [subscription, setSubscription] = useState({
        clubId: club.id,
        groupId: groupId,
        customerId: customer ? customer.id : null,
        profileId: (customer && customer.profiles.length > 0) ? customer.profiles[0].id : null,
        trainings: [],
        fields: [],
        files: [],
        options: []
    });

    useEffect(() => {
        axios.get(process.env.REACT_APP_PUBLIC_API_URL + 'groups/' + groupId)
            .then(response => {
                console.log(response.data);
                if(response.data) {
                    setGroup(response.data);
                    setIsLoaded(true);
                } else {
                    navigate('/clubs');
                }
            })
            .catch(err => console.log(err));
    }, [groupId]);

    const createAccount = () => {
        setButtonLoading(true);
        axios.post(process.env.REACT_APP_PUBLIC_API_URL + "sign-up", {...account, clubId: club.id })
            .then((response) => {
                localStorage.setItem('session_token', response.data.token);
                setToken(response.data.token);
                toast.success("Bienvenue, " + account.firstName + ' !', toastOptions);
            })
            .catch((error) => {
                setError(error.response.data);
                setButtonLoading(false);
            });
    }

    const submitSubscription = () => {

        setButtonLoading(true);
        setError(null);

        const formData = new FormData();
        formData.append('subscription', JSON.stringify(subscription));
        formData.append('licenceFile', subscription.licenceFile);

        for(const file of  subscription.files) {
            const renamedFile = new File([file.content], `${file.id}.${file.content.name.split('.').pop()}`, { type: file.content.type });
            formData.append('customerFiles', renamedFile);
        }

        axios.post(process.env.REACT_APP_PUBLIC_API_URL + 'subscriptions', formData, {
            headers : {
                'Content-Type': 'multipart/form-data',
                Authorization: 'Bearer ' + token
            }
        })
            .then(() => {
                navigate('/my-account/subscriptions?result=success');
                setAppRefresh(appRefresh + 1);
                setButtonLoading(false);
            })
            .catch((error) => {
                setButtonLoading(false);
                setError(error.response.data);
            });
    }

    const handleChooseGroup = (training) => {
        if (!subscription.trainings.some(t => t.id === training.id) && subscription.trainings.length < getMaximumAllowedNb(group.rates) && (training.places - training.subscriptionsCount) > 0) {
            setSubscription({
                ...subscription,
                trainings: [...subscription.trainings, training]
            });
        } else {
            setSubscription({
                ...subscription,
                trainings: [...subscription.trainings].filter(selectedTraining => selectedTraining.id !== training.id)
            });
        }
    }

    const handleChangeFieldValue = (field, newValue) => {
        const newFields = [...subscription.fields].filter(f => f.id !== field.id);
        setSubscription({...subscription, fields: [...newFields, {id: field.id, value: newValue }]})
        console.log({...subscription, fields: [...newFields, {id: field.id, value: newValue }]});
    }

    const handleChangeFile = (file, newFile) => {
        if(newFile && newFile.size <= 5242880 && ['image/jpeg', 'image/png', 'application/pdf'].includes(newFile.type)) {
            const newFiles = [...subscription.files].filter(f => f.id !== file.id);
            setSubscription({...subscription, files: [...newFiles, {id: file.id, content: newFile }]});
        } else {
            window.alert('Le fichier est trop volumineux ou au mauvais format. Formats acceptés : PDF, PNG, JPG. Taille maximale : 5Mo');
        }
    }

    const getMaximumAllowedNb = (rates) => {
        return rates.reduce((maxAllowedNb, rate) => {
            return Math.max(maxAllowedNb, rate.allowedNb);
        }, 0);
    };

    return isLoaded ? (
        <div className='club-view-container'>
            <div className='club-info-container'>
                <div className='club-view-filters'>
                    <div className='view-subtitle user'>
                        <h2>Entraineur(s)</h2>
                    </div>
                    <div className='club-coaches-container'>
                        { group.coaches.map((coach, index) => (
                            <CustomTag key={index} value={coach.firstName + ' ' + coach.lastName} />
                        ))}
                    </div>
                </div>
                <div className='club-view-filters'>
                    <div className='view-subtitle price'>
                        <h2>Tarif(s)</h2>
                    </div>
                    <TableContainer className='margin-bottom-15'>
                        <Table size="small" aria-label="Tarifs">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Séance(s)</TableCell>
                                    <TableCell>Tarif</TableCell>
                                </TableRow>
                            </TableHead>
                            {group.trainingsType === 'optional' ? (
                                <TableBody>
                                    {group.rates.map((rate) => (
                                        <TableRow key={rate.id}>
                                            <TableCell component="th" scope="row">{rate.allowedNb} séance(s)</TableCell>
                                            <TableCell>{rate.price}€</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            ) : (
                                <TableBody>
                                    {group.rates.map((rate) => (
                                        <TableRow key={rate.id}>
                                            <TableCell component="th"
                                                       scope="row">{group.trainings.length} séance(s)</TableCell>
                                            <TableCell>{rate.price}€</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                    <span className='margin-top very-small-note'>Tarif(s) incluant 72€ de licence + adhésion</span>
                </div>
            </div>
            <div className='club-view-group'>
                <div className='club-group-container'>
                    <div className='club-group-titles'>
                        <h1>{group.name}</h1>
                        <IconButton aria-label="delete" size="small" onClick={() => navigate(-1)}>
                            <ArrowBackRoundedIcon fontSize="small" />
                        </IconButton>
                    </div>
                    <div className='club-group-form'>
                        { dayjs().isBetween(club.season.subscriptionStart, club.season.subscriptionStop) ? (
                            <div>
                                { !customer ? (
                                    <div>
                                        <div className='view-subtitle'>
                                            <h2>Créez votre compte</h2>
                                        </div>
                                        <p className='small'>Vous devez posséder un compte pour vous inscrire. Remplissez le formulaire ci-dessous pour créer votre compte. Déjà un compte ? <Link to='/login'>Se connecter</Link></p>
                                        <div className='form-info-container'>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} md={6}>
                                                    <TextField
                                                        required
                                                        error={error && error.missingFields && error.missingFields.includes('firstName')}
                                                        label="Prénom"
                                                        variant="outlined"
                                                        value={account.firstName}
                                                        onChange={(e) => setAccount({...account, firstName: e.target.value })}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <TextField
                                                        required
                                                        error={error && error.missingFields && error.missingFields.includes('lastName')}
                                                        label="Nom"
                                                        variant="outlined"
                                                        value={account.lastName}
                                                        onChange={(e) => setAccount({...account, lastName: e.target.value })}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        required
                                                        label="Email"
                                                        variant="outlined"
                                                        error={error && error.missingFields && error.missingFields.includes('email')}
                                                        value={account.email}
                                                        onChange={(e) => setAccount({...account, email: e.target.value })}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        required
                                                        label="Mot de passe"
                                                        type='password'
                                                        variant="outlined"
                                                        error={error && error.missingFields && error.missingFields.includes('password')}
                                                        value={account.password}
                                                        onChange={(e) => setAccount({...account, password: e.target.value })}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </div>
                                        { error ? (
                                            <div className='error-message'>
                                                <span>{error.message ? error.message : 'Une erreur est survenue'}</span>
                                                { error.details && error.details.map((detail, index) => (
                                                    <span key={index}>- {detail}</span>
                                                ))}
                                            </div>
                                        ) : null }
                                        <button className='main-button blue-button submit-subcription' disabled={buttonLoading} onClick={createAccount}>{buttonLoading ? <CircularProgress className='white-loader' size={20} /> : 'Créer mon compte'}</button>

                                    </div>
                                ) : (
                                    <div>
                                        { group.trainingsType === 'mandatory' && group.places - group.subscriptionsCount <= 0 ? (
                                            <div className='error-message'>
                                                <span>Ce groupe n'a plus de places disponibles.</span>
                                            </div>
                                        ) : null }
                                        <div>
                                            <div className='view-subtitle'>
                                                <h2>Choisissez un profil</h2>
                                            </div>
                                            <div className='form-info-container'>
                                                <Grid container spacing={2}>
                                                    { customer.profiles.length > 0 && customer.profiles.map((customerProfile, index) => (
                                                        <Grid item xs={6} md={4} key={index}>
                                                            <ProfileEdit
                                                                isClickable={false}
                                                                customerProfile={customerProfile}
                                                                onClick={() => setSubscription({...subscription, profileId: customerProfile.id })}
                                                                isSelected={subscription.profileId && subscription.profileId === customerProfile.id}
                                                                className={subscription.profileId && subscription.profileId === customerProfile.id ? 'active' : ''}
                                                                appRefresh={appRefresh}
                                                                setAppRefresh={setAppRefresh}
                                                            />
                                                        </Grid>
                                                    ))}
                                                    <Grid item xs>
                                                        <div className='profile-create-chooser'>
                                                            <div className='profile-create-block' onClick={() => setProfileModal(true)}>
                                                                <img src={add_logo} alt='me' width={20} />
                                                                <span>Ajouter un profil</span>
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                                <ProfileAdd
                                                    modalOpen={profileModal}
                                                    setModalOpen={setProfileModal}
                                                    appRefresh={appRefresh}
                                                    setAppRefresh={setAppRefresh}
                                                />
                                            </div>
                                        </div>
                                        { group.trainingsType === 'optional' ? (
                                            <div className='form-info-container'>
                                                <div className='view-subtitle calendar'>
                                                    <h2>Choisissez vos séances</h2>
                                                </div>
                                                <div className='training-selector-container'>
                                                    <Grid container spacing={2}>
                                                        { group.trainings.map((training, index) => (
                                                            <Grid item key={index} xs={12} md={6}>
                                                                <div className={'training-selector-choice ' + (!subscription.trainings.some(t => t.id === training.id) && subscription.trainings.length >= getMaximumAllowedNb(group.rates) ? 'disabled ' : ' ') + (training.places - training.subscriptionsCount <= 0 ? ' disabled' : ' ')}>
                                                                    <div>
                                                                        <div className='training-selector-line date'>
                                                                            <CustomTag
                                                                                value={constants.all.find(constant => constant.value === training.weekDay).label}/>
                                                                            <span>de</span>
                                                                            <CustomTag
                                                                                value={dayjs(dayjs().format('YYYY-MM-DD ' + training.startHour)).format('HH:mm')}/>
                                                                            <span>à</span>
                                                                            <CustomTag
                                                                                value={dayjs(dayjs().format('YYYY-MM-DD ' + training.stopHour)).format('HH:mm')}/>
                                                                        </div>
                                                                        <div className='training-selector-line date'>
                                                                            <CustomTag value={training.pool.name}
                                                                                       className='pool-info'/>
                                                                        </div>
                                                                        <div className='training-selector-line date'>
                                                                            <CustomTag
                                                                                value={Math.max(0, (training.places - training.subscriptionsCount)) + ' places disponibles'}
                                                                                className={'pool-info ' + (training.places - training.subscriptionsCount <= 0 ? 'refused' : 'accepted')}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <label className="checkbox-container">
                                                                            <input
                                                                                type="checkbox"
                                                                                disabled={(!subscription.trainings.some(t => t.id === training.id) && subscription.trainings.length >= getMaximumAllowedNb(group.rates)) || (training.places - training.subscriptionsCount <= 0)}
                                                                                checked={subscription.trainings.some(t => t.id === training.id)}
                                                                                onClick={() => handleChooseGroup(training)}
                                                                            />
                                                                            <span className="checkmark"/>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </Grid>
                                                        ))}
                                                    </Grid>
                                                </div>
                                            </div>
                                        ) : null}
                                        <div>
                                            { customer.profiles.length > 0 ? (
                                                <LicenceForm
                                                    group={group}
                                                    customer={customer}
                                                    profileId={subscription.profileId}
                                                    subscription={subscription}
                                                    setSubscription={setSubscription}
                                                    handleChangeFile={handleChangeFile}
                                                />
                                            ) : null }
                                        </div>
                                        { group.fields.filter(f => f.isPublic).length > 0 ? (
                                            <div className='form-info-container field'>
                                                <Grid container spacing={2}>
                                                    { group.fields.filter(f => f.isPublic).map((field, index) => (
                                                        <Grid item xs={12} md={6}>
                                                            <div className='view-subtitle field'>
                                                                <h2>{field.label} <span className='subtitle-required'>{field.required ? '*' : ''}</span></h2>
                                                                { field.description ? (
                                                                    <Tooltip enterTouchDelay={0} leaveTouchDelay={5000} placement='top' id='options-tooltip' title={
                                                                        <React.Fragment>
                                                                            <CustomRichText value={field.description} readMode className="padding" />
                                                                        </React.Fragment>
                                                                    }>
                                                                        <IconButton aria-label="delete" size="small">
                                                                            <HelpIcon fontSize="small" />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                ) : null }
                                                            </div>
                                                            <CustomField
                                                                field={field}
                                                                key={index}
                                                                error={fieldError && fieldError.missingFields.some(f => f.id === field.id)}
                                                                setField={handleChangeFieldValue}
                                                                value={subscription.fields.some(f => f.id === field.id) ? subscription.fields.find(f => f.id === field.id).value : null}
                                                            />
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            </div>
                                        ) : null }
                                        { group.files.length > 0 ? (
                                            <div className='form-info-container'>
                                                { group.files.map((file, index) => (
                                                    <>
                                                        <div className={'view-subtitle file ' + (file.description ? 'with-description' : '')}>
                                                            <h2>{file.label} <span className='subtitle-required'>{file.required ? '*' : ''}</span></h2>
                                                        </div>
                                                        <CustomRichText value={file.description} readMode />
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12}>
                                                                <CustomFile
                                                                    templateLink={file.hasTemplate ? (process.env.REACT_APP_FILES_API_URL + 'templates/' + file.id + file.templateExtension) : null}
                                                                    fileInfo={file}
                                                                    key={index}
                                                                    selectedFile={subscription.files.find(f => f.id === file.id)}
                                                                    changeFile={handleChangeFile}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </>
                                                ))}
                                            </div>
                                        ) : null }
                                        { club.season.options.length > 0 ? (
                                            <div>
                                                <div className='view-subtitle options'>
                                                    <h2>Options disponibles</h2>
                                                </div>
                                                <div className='training-selector-container'>
                                                    { club.season.options.map((option, index) => (
                                                        <div key={index} className='training-selector-block'>
                                                            <label className="checkbox-container">
                                                                <input
                                                                    type="checkbox"
                                                                    onClick={(event) => {
                                                                        if(event.target.checked) {
                                                                            setSubscription({...subscription, options: [...subscription.options, option]});
                                                                        } else {
                                                                            setSubscription({...subscription, options: [...subscription.options].filter(o => o.id !== option.id)});
                                                                        }
                                                                    }}
                                                                />
                                                                <span className="checkmark"/>
                                                            </label>
                                                            <span>{option.name}</span>
                                                            <CustomTag value={option.value + ' €'} />
                                                            { option.description ? (
                                                                <Tooltip enterTouchDelay={0} leaveTouchDelay={5000} placement='top' id='options-tooltip' title={
                                                                    <React.Fragment>
                                                                        <CustomRichText value={option.description} readMode className="padding" />
                                                                    </React.Fragment>
                                                                }>
                                                                    <IconButton aria-label="delete" size="small">
                                                                        <HelpIcon fontSize="small" />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            ) : null }
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        ) : null }
                                        <div className='view-subtitle price'>
                                            <h2>Choix du mode de paiement</h2>
                                        </div>
                                        <div className='payment-selector-container'>
                                            <RadioGroup
                                                onChange={(event) => setSubscription({...subscription, paymentModeId: event.target.value})}
                                            >
                                                { club.season.paymentModes.map((paymentMode, index) => (
                                                    <div className='payment-selector-block'>
                                                        <FormControlLabel
                                                            key={index}
                                                            value={paymentMode.id}
                                                            control={<Radio size='small' />}
                                                            label={paymentMode.name}
                                                        />
                                                        { paymentMode.description ? (
                                                            <Tooltip enterTouchDelay={0} leaveTouchDelay={5000} placement='top' id='options-tooltip' title={
                                                                <React.Fragment>
                                                                    <CustomRichText value={paymentMode.description} readMode className="padding" />
                                                                </React.Fragment>
                                                            }>
                                                                <IconButton aria-label="delete" size="small">
                                                                    <HelpIcon fontSize="small" />
                                                                </IconButton>
                                                            </Tooltip>
                                                        ) : null }
                                                    </div>
                                                ))}
                                            </RadioGroup>
                                        </div>
                                        { error ? (
                                            <div className='error-message'>
                                                <span>{error.message ? error.message : 'Une erreur est survenue'}</span>
                                                { error.details && error.details.map((detail, index) => (
                                                    <span key={index}>- {detail}</span>
                                                ))}
                                            </div>
                                        ) : null }
                                        <div className='general-condition-info'>
                                            <span>En cliquant sur "Envoyer la demande", vous acceptez l'ensemble des conditions générales de vente, le règlement intérieur et le droit à l'image du club.</span>
                                        </div>
                                        <button
                                            className='main-button blue-button submit-subcription'
                                            disabled={buttonLoading || (group.trainingsType === 'mandatory' && group.places - group.subscriptionsCount <= 0)}
                                            onClick={submitSubscription}
                                        >
                                            {buttonLoading ? <CircularProgress className='white-loader' size={20} /> : 'Envoyer la demande'}
                                        </button>

                                    </div>
                                )}
                            </div>
                        ) : dayjs().isBefore(dayjs(club.season.subscriptionStart)) ? (
                            <div className='subscription-closed-info'>
                                <img src={closed_logo} alt='closed' width={100}/>
                                <h3>Les inscriptions ne sont pas encore ouvertes !</h3>
                                <h3>Rendez-vous le {dayjs(club.season.subscriptionStart).format('DD MMMM YYYY')}.</h3>
                            </div>
                        ) : (
                            <div className='subscription-closed-info'>
                                <img src={closed_logo} alt='closed' width={100}/>
                                <h3>Les inscriptions sont terminées !</h3>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    ) : <div className='loader-container'><CircularProgress size={30}/></div>;
}

export default GroupView;