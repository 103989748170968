import {Tooltip} from "@mui/material";
import button from "bootstrap/js/src/button";
import React, {useContext, useState} from "react";
import AuthContext from "../../../contexts/AuthContext";

const PlanningFilter = ({ activeGroups, events, setEvents }) => {

    const { user } = useContext(AuthContext);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [groups, setGroups] = useState(activeGroups.map(g => ({ id: g.id, name: g.name, active: user.role === 'club-coach' ? g.coaches.some(c => c.id === user.id) : true, color: g.color })));

    React.useEffect(() => {
        if(events.length > 0) {
            setEvents(events.map(e => ({...e, active: groups.find(g => g.id === e.training.group.id).active })));
        }
    }, [groups]);

    return (
        <div>
            <Tooltip enterTouchDelay={0} leaveTouchDelay={5000} leaveDelay={500} placement="bottom-end" id='options-tooltip' className='planning-tooltip' title={
                <React.Fragment>
                    <div className='planning-filter'>
                        <div className='planning-filter-select'>
                            <span onClick={() => setGroups(groups.map(g => ({...g, active: true })))}>Tout sélectionner</span>
                            <span onClick={() => setGroups(groups.map(g => ({...g, active: false })))}>Tout désélectionner</span>
                        </div>
                        { groups.map((group, index) => (
                            <div className='planning-filter-row' key={index}>
                                <label className="checkbox-container">
                                    <input
                                        type="checkbox"
                                        checked={group.active}
                                        onClick={() => setGroups(groups.map(g => g.id === group.id ? { ...g, active: !g.active } : g))}
                                    />
                                    <span className="checkmark" style={{
                                        backgroundColor: group.active ? group.color : '#f5f5f5',
                                        borderColor: group.active ? group.color : '#dedede'
                                    }} />
                                </label>
                                <span>{group.name}</span>
                            </div>
                        ))}
                    </div>
                </React.Fragment>
            }>
                <button className='main-button planning-filter-button' onClick={() => setTooltipOpen(!tooltipOpen)}>{groups.filter(g => g.active).length} groupe(s) sélectionné(s)</button>
            </Tooltip>
        </div>
    );
}

export default PlanningFilter;