const constants = {}

constants.roles = [
    { value: 'club-president', label: 'Président' },
    { value: 'club-secretary', label: 'Secrétaire général' },
    { value: 'club-treasurer', label: 'Trésorier' },
    { value: 'club-manager', label: 'Autre dirigeant' },
    { value: 'club-volunteer', label: 'Bénévole' },
    { value: 'club-coach', label: 'Entraineur' }
];

constants.civilities = [
    { value: 'mister', label: 'Monsieur' },
    { value: 'miss', label: 'Madame' }
];

constants.status = [
    { value: 'active', label: 'Actif' },
    { value: 'inactive', label: 'Inactif' },
    { value: 'progress', label: 'En cours' },
    { value: 'archived', label: 'Archivé' },
    { value: 'waiting-validation', label: 'En attente de validation' },
    { value: 'change-needed', label: 'En attente de changement' },
    { value: 'waiting-test', label: 'En attente de test' },
    { value: 'waiting-payment', label: 'En attente du paiement' },
    { value: 'refused', label: 'Refusée' },
    { value: 'accepted', label: 'Acceptée' },
    { value: 'dropped', label: 'Abandonné' },
];

constants.groupStatus = [
    { value: 'waiting-validation', label: 'En attente de validation' },
    { value: 'change-needed', label: 'En attente de changement' },
    { value: 'waiting-test', label: 'En attente de test' },
    { value: 'waiting-payment', label: 'En attente du paiement' },
    { value: 'refused', label: 'Refusée' },
    { value: 'accepted', label: 'Acceptée' }
];

constants.paymentStatus = [
    { value: 'paid', label: 'Payé' },
    { value: 'waiting-payment', label: 'En attente du paiement' },
    { value: 'failed', label: 'Échoué' },
    { value: 'refunded', label: 'Remboursé' },
    { value: 'cancelled', label: 'Annulé' },
];

constants.fields = [
    { value: 'date', label: 'Date' },
    { value: 'text', label: 'Texte' },
    { value: 'number', label: 'Nombre' },
    { value: 'select', label: 'Liste déroulante' }
];

constants.discountTypes = [
    { value: 'amount', label: 'Montant' },
    { value: 'percentage', label: 'Pourcentage' }
];

constants.frequencies = [
    { value: 'days', label: 'jours' },
    { value: 'weeks', label: 'semaines' },
    { value: 'months', label: 'mois' }
];

constants.weekDays = [
    { value: 'monday', label: 'Lundi' },
    { value: 'tuesday', label: 'Mardi' },
    { value: 'wednesday', label: 'Mercredi' },
    { value: 'thursday', label: 'Jeudi' },
    { value: 'friday', label: 'Vendredi' },
    { value: 'saturday', label: 'Samedi' },
    { value: 'sunday', label: 'Dimanche' },
];

constants.supportGroupTypes = [
    {
        value: "coach",
        label: "Entraîneur",
        groups: [
            { value: 'coach-swimming', label: 'Natation'},
            { value: 'coach-artistic-swimming', label: 'Natation artistique'},
            { value: 'coach-diving', label: 'Plongeon' },
            { value: 'coach-water-polo', label: 'Water polo' },
            { value: 'coach-open-water', label: 'Eau libre' },
            { value: 'coach-health', label: 'Nagez Forme Santé' },
            { value: 'coach-wellness', label: 'Nagez Forme Bien-être' },
        ]
    },
    {
        value: "official",
        label: "Officiel",
        groups: [
            { value: 'official-swimming', label: 'Natation' },
            { value: 'official-artistic-swimming', label: 'Natation artistique' },
            { value: 'official-diving', label: 'Plongeon' },
            { value: 'official-water-polo', label: 'Water polo' },
            { value: 'official-open-water', label: 'Eau libre' },
        ]
    }
];

constants.groupTypes = [
    {
        value: "leisure",
        label: "Natation pour tous",
        groups: [
            { value: 'leisure-swimming', label: 'Natation' },
            { value: 'leisure-artistic-swimming', label: 'Natation artistique' },
            { value: 'leisure-diving', label: 'Plongeon' },
            { value: 'leisure-water-polo', label: 'Water polo' },
            { value: 'leisure-open-water', label: 'Eau libre' },
            { value: 'leisure-health', label: 'Nagez Forme Santé' },
            { value: 'leisure-wellness', label: 'Nagez Forme Bien-être' },
        ]
    },
    {
        value: "competition",
        label: "Compétition",
        groups: [
            { value: 'competition-swimming', label: 'Natation' },
            { value: 'competition-artistic-swimming', label: 'Natation artistique' },
            { value: 'competition-diving', label: 'Plongeon' },
            { value: 'competition-water-polo', label: 'Water polo' },
            { value: 'competition-open-water', label: 'Eau libre' },
            { value: 'competition-promotional-open-water', label: 'Eau libre promotionnelle' },
        ]
    },
    {
        value: "ministerial",
        label: "Opération ministérielle",
        groups: [
            { value: 'ministerial-swimming', label: "J'apprends à nager / Aisance aquatique" }
        ]
    }
];

constants.healthQuestions = [
    {
        value: "major",
        label: "Majeurs",
        categories: [
            {
                label: "Durant les 12 derniers mois",
                questions: [
                    { id: 1, value: "Un membre de votre famille est-il décédé subitement d'une cause cardiaque ou inexpliquée ?", yes: [507, 205], no: [540, 205] },
                    { id: 2, value: "Avez-vous ressenti une douleur dans la poitrine, des palpitations, un essouflement inhabituel ou un malaise ?", yes: [507, 234], no: [540, 234] },
                    { id: 3, value: "Avez-vous eu un épisode de respiration sifflante (asthme) ?", yes: [507, 264], no: [540, 264] },
                    { id: 4, value: "Avez-vous eu une perte de connaissance ?", yes: [507, 292], no: [540, 292] },
                    { id: 5, value: "Si vous avez arrêté le sport pendant 30 jours ou plus pour des raisons de santé, avez-vous repris sans l'accord d'un médecin ?", yes: [507, 320], no: [540, 320] },
                    { id: 6, value: "Avez-vous débuté un traitement médical de longue durée (hors contraception et désensibilisation aux allergies) ?", yes: [507, 355], no: [540, 355] }
                ]
            },
            {
                label: "À ce jour",
                questions: [
                    { id: 7, value: "Ressentez-vous une douleur, un manque de force ou une raideur suite à un problème osseux, articulaire ou musculaire (fracture, entorse, luxation, déchirure, tendinite, etc...) survenu durant les 12 derniers mois ?", yes: [507, 413], no: [540, 413] },
                    { id: 8, value: "Votre pratique sportive est-elle interrompue pour des raisons de santé ?", yes: [507, 453], no: [540, 453] },
                    { id: 9, value: "Pensez-vous avoir besoin d'un avis médical pour poursuivre votre pratique sportive ?", yes: [507, 482], no: [540, 482] }
                ]
            }
        ]
    },
    {
        value: "minor",
        label: "Mineurs",
        categories: [
            {
                label: "Depuis l'année dernière",
                questions: [
                    { id: 10, value: "Es-tu allé (e) à l'hôpital pendant toute une journée ou plusieurs jours ?", yes: [0, 0], no: [0, 0], page: 1 },
                    { id: 11, value: "As-tu été opéré (e) ?", yes: [0, 0], no: [0, 0], page: 1 },
                    { id: 12, value: "As-tu beaucoup plus grandi que les autres années ?", yes: [0, 0], no: [0, 0], page: 1 },
                    { id: 13, value: "As-tu beaucoup maigri ou grossi ?", yes: [0, 0], no: [0, 0], page: 1 },
                    { id: 14, value: "As-tu eu la tête qui tourne pendant un effort ?", yes: [0, 0], no: [0, 0], page: 1 },
                    { id: 15, value: "As-tu perdu connaissance ou es-tu tombé sans te souvenir de ce qui s'était passé ?", yes: [0, 0], no: [0, 0], page: 1 },
                    { id: 16, value: "As-tu reçu un ou plusieurs chocs violents qui t'ont obligé à interrompre un moment une séance de sport ?", yes: [0, 0], no: [0, 0], page: 1 },
                    { id: 17, value: "As-tu eu beaucoup de mal à respirer pendant un effort par rapport à d'habitude ?", yes: [0, 0], no: [0, 0], page: 1 },
                    { id: 18, value: "As-tu eu beaucoup de mal à respirer après un effort ?", yes: [0, 0], no: [0, 0], page: 1 },
                    { id: 19, value: "As-tu eu mal dans la poitrine ou des palpitations (le cœur qui bat très vite) ?", yes: [0, 0], no: [0, 0], page: 1 },
                    { id: 20, value: "As-tu commencé à prendre un nouveau médicament tous les jours et pour longtemps ?", yes: [0, 0], no: [0, 0], page: 1 },
                    { id: 21, value: "As-tu arrêté le sport à cause d'un problème de santé pendant un mois ou plus ?", yes: [0, 0], no: [0, 0], page: 1 }
                ]
            },
            {
                label: "Depuis un certain temps (plus de 2 semaines)",
                questions: [
                    { id: 22, value: "Te sens-tu très fatigué (e) ?", yes: [0, 0], no: [0, 0], page: 1 },
                    { id: 23, value: "As-tu du mal à t'endormir ou te réveilles-tu souvent la nuit ?", yes: [0, 0], no: [0, 0], page: 1 },
                ]
            },
            {
                label: "Depuis l'année dernière",
                questions: [
                    { id: 24, value: "Sens-tu que tu as moins faim ? que tu manges moins ?", yes: [0, 0], no: [0, 0], page: 2 },
                    { id: 25, value: "Te sens-tu triste ou inquiet ?", yes: [0, 0], no: [0, 0], page: 2 },
                    { id: 26, value: "Pleures-tu plus souvent ?", yes: [0, 0], no: [0, 0], page: 2 },
                    { id: 27, value: "Ressens-tu une douleur ou un manque de force à cause d'une blessure que tu t'es faite cette année ?", yes: [0, 0], no: [0, 0], page: 2 }
                ]
            },
            {
                label: "Aujourd'hui",
                questions: [
                    { id: 28, value: "Penses-tu quelquefois à arrêter de faire du sport ou à changer de sport ?", yes: [0, 0], no: [0, 0], page: 2 },
                    { id: 29, value: "Penses-tu avoir besoin de voir ton médecin pour continuer le sport ?", yes: [0, 0], no: [0, 0], page: 2 },
                    { id: 30, value: "Souhaites-tu signaler quelque chose de plus concernant ta santé ?", yes: [0, 0], no: [0, 0], page: 2 }
                ]
            },
            {
                label: "Questions à faire remplir par tes parents",
                questions: [
                    { id: 31, value: "Quelqu'un dans votre famille proche a-t-il eu une maladie grave du cœur ou du cerveau, ou est-il décédé subitement avant l'âge de 50 ans?", yes: [0, 0], no: [0, 0], page: 2 },
                    { id: 32, value: "Etes-vous inquiet pour son poids? Trouvez-vous qu'il se nourrit trop ou pas assez?", yes: [0, 0], no: [0, 0], page: 2 },
                    { id: 33, value: "Avez-vous manqué l'examen de santé prévu à l'âge de votre enfant chez le médecin ? (Cet examen médical est prévu à l'âge de 2 ans, 3 ans, 4 ans, 5 ans, entre 8 et 9 ans, entre 11 et 13 ans et entre 15 et 16 ans.)", yes: [0, 0], no: [0, 0], page: 2 }
                ]
            }
        ]
    }
];

constants.all = constants.roles.concat(constants.status);
constants.all = constants.all.concat(constants.civilities);
constants.all = constants.all.concat(constants.weekDays);
constants.all = constants.all.concat(constants.frequencies);
constants.all = constants.all.concat(constants.fields);
constants.all = constants.all.concat(constants.discountTypes);
constants.all = constants.all.concat(constants.paymentStatus);

export default constants;