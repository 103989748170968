import React, {useContext, useRef, useState} from "react";
import { Button, CircularProgress, FormControl, FormControlLabel, Grid, IconButton, Radio, RadioGroup, TextField, Tooltip } from "@mui/material";
import CustomRichText from "../../custom/CustomRichText";
import HelpIcon from "@mui/icons-material/Help";
import axios from "axios";
import AuthContext from "../../../contexts/AuthContext";
import { Page, Document, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/TextLayer.css';
import {DateField} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import SignatureCanvas from "react-signature-canvas";
import {toast} from "react-toastify";
import toastOptions from "../../../assets/utils/toast";
import HealthForm from "./HealthForm";
import IntegrityCheck from "./IntegrityCheck";
import constants from "../../../assets/utils/constants";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const LicenceSign = ({ group, customer, profileId, subscription, setSubscription, open, setOpen, isUser = false, appRefresh, setAppRefresh }) => {

    let sigPad = {};
    const { token } = useContext(AuthContext);
    const containerRef = useRef(null);
    const errorMessageRef = useRef(null);
    const selectedProfile = customer.profiles.find(profile => profile.id === profileId);
    const [loading, setLoading] = useState(false);
    const [pdfData, setPdfData] = useState(null);
    const [numPages, setNumPages] = useState(null);
    const [containerWidth, setContainerWidth] = useState(400);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [verification, setVerification] = useState(false);
    const [error, setError] = useState(null);
    const [licence, setLicence] = useState({
        isUser: isUser,
        userRole: isUser ? customer.role : null,
        profile: selectedProfile,
        type: selectedProfile && selectedProfile.subscriptions.length > 0 ? "renew" : "new",
        email: customer.email,
        groupType: !isUser ? group.licenceType : null,
        integrityCheck: {
            bornAbroad: false,
            commonFirstName: selectedProfile ? selectedProfile.firstName : '',
            commonLastName: selectedProfile ? selectedProfile.lastName : '',
        },
        supportGroups: []
    });

    const onDocumentLoadSuccess = ({ numPages }) => {
        updateWidth();
        setNumPages(numPages);
    }

    const updateWidth = () => {
        if (containerRef.current) setContainerWidth((containerRef.current.offsetWidth - 40) > 761 ? 761 : (containerRef.current.offsetWidth - 40));
    };

    React.useEffect(() => {
        window.addEventListener('resize', updateWidth);
        return () => window.removeEventListener('resize', updateWidth);
    }, []);

    React.useEffect(() => updateWidth, [containerRef.current, verification]);

    React.useEffect(() => setLicence({
        isUser: isUser,
        userRole: isUser ? customer.role : null,
        profile: selectedProfile,
        type: selectedProfile && selectedProfile.subscriptions.length > 0 ? "renew" : "new",
        email: customer.email,
        groupType: !isUser ? group.licenceType : null,
        integrityCheck: {
            bornAbroad: false,
            commonFirstName: selectedProfile ? selectedProfile.firstName : '',
            commonLastName: selectedProfile ? selectedProfile.lastName : '',
        },
        supportGroups: []
    }), [profileId]);

    React.useEffect(() => {
        if (error) errorMessageRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [error]);

    React.useEffect(() => {
        updateWidth();
        const debounce = setTimeout(() => {
            setLoading(true);
            axios.post(isUser ? process.env.REACT_APP_ADMIN_API_URL + 'users/licence/preview' : process.env.REACT_APP_PUBLIC_API_URL + 'customer/licence/preview', licence, { headers: { Authorization: 'Bearer ' + token }, responseType: 'blob' })
                .then(response => {
                    const file = new Blob([response.data], { type: 'application/pdf' });
                    setPdfData(URL.createObjectURL(file));
                    setLoading(false);
                })
                .catch(error => {
                    console.log(error);
                    setLoading(false);
                });
        }, 500);
        return () => clearTimeout(debounce);
    }, [licence, open]);

    const resetPad = () => {
        sigPad.clear();
        setLicence({...licence, signature: null, signatureSize: 0});
    }

    const handleValidate = () => {
        setError(null);
        setButtonLoading(true);
        axios.post(isUser ? process.env.REACT_APP_ADMIN_API_URL + 'users/licence/check' : process.env.REACT_APP_PUBLIC_API_URL + 'customer/licence/check', licence, {headers: { Authorization: 'Bearer ' + token }})
            .then(() => {

                setButtonLoading(false);
                setVerification(true);
            })
            .catch(error => {
                setError(error.response.data);
                errorMessageRef.current?.scrollIntoView({ behavior: 'smooth' });
                setButtonLoading(false);
            });
    }

    const handleSign = () => {
        setButtonLoading(true);
        axios.post(isUser ? process.env.REACT_APP_ADMIN_API_URL + 'users/licence/sign' : process.env.REACT_APP_PUBLIC_API_URL + 'customer/licence/sign', licence, { headers: { Authorization: 'Bearer ' + token }, responseType: 'blob' })
            .then(response => {
                if(!isUser) {
                    const blob = new Blob([response.data], { type: 'application/pdf' });
                    const file = new File([blob], 'licence.pdf');
                    setSubscription({...subscription,
                        licenceFile: file,
                        doping: licence.doping,
                        basicWarranty: licence.basicWarranty,
                        complementaryWarranty: licence.complementaryWarranty,
                        ads: licence.ads,
                        needsCertificate: licence.healthForm && Object.values(licence.healthForm).includes(true),
                    });
                    toast.success("Votre licence a bien été signée !", toastOptions);
                    setOpen(false);
                    setButtonLoading(false);
                } else {
                    toast.success("Votre licence a bien été signée !", toastOptions);
                    setOpen(false);
                    setButtonLoading(false);
                    setAppRefresh(appRefresh + 1);
                }
            })
            .catch(error => {
                console.log(error);
                setButtonLoading(false);
            });
    }

    const handleCheckboxChange = (groupValue) => {
        setLicence((prevLicence) => {
            const newSupportGroups = prevLicence.supportGroups.includes(groupValue)
                ? prevLicence.supportGroups.filter((value) => value !== groupValue)
                : [...prevLicence.supportGroups, groupValue];
            return { ...prevLicence, supportGroups: newSupportGroups };
        });
    };

    return (
        <div className={`licence-sign-container ${open ? 'open' : ''} ${verification ? 'verification' : ''}`}>
            <div className={`licence-sign-pdf-container `} ref={containerRef}>
                { !loading ? (
                    <>
                        { pdfData && (
                            <Document file={pdfData} onLoadSuccess={onDocumentLoadSuccess} loading={
                                <div className='pdf-loader-container'>
                                    <CircularProgress size={50}/>
                                </div>
                            }>
                                { Array.from(new Array(numPages), (el, index) => (
                                    <Page
                                        key={`page_${index + 1}`}
                                        pageNumber={index + 1}
                                        renderAnnotationLayer={false}
                                        renderTextLayer={false}
                                        width={containerWidth}
                                        loading={
                                            <div className='pdf-loader-container'>
                                                <CircularProgress size={50}/>
                                            </div>
                                        }
                                    />
                                ))}
                            </Document>
                        )}
                    </>
                ) : (
                    <div className='pdf-loader-container'>
                        <CircularProgress size={50} />
                    </div>
                )}
            </div>
            <div className='licence-sign-form-container'>
                <div className='licence-sign-form-header'>
                    <h1>Compléter votre licence</h1>
                    <button className='modal-close' onClick={() => setOpen(false)}>+</button>
                </div>
                <div className='licence-sign-form-body'>
                    <span className='licence-sign-note margin-bottom-15'>Remplissez le formulaire ci-dessous afin de remplir automatiquement votre licence.</span>
                    <Grid container alignItems="center" spacing={2} className="margin-bottom-15">
                        <Grid item>
                            <FormControl>
                                <RadioGroup
                                    row
                                    defaultValue={licence.type}
                                    name="radio-buttons-group"
                                    onChange={(e) => setLicence({...licence, type: e.target.value})}
                                >
                                    <FormControlLabel value="new" control={<Radio size="small"/>}
                                                      label="Nouvelle licence"/>
                                    <FormControlLabel value="renew" control={<Radio size="small"/>}
                                                      label="Renouvellement"/>
                                    <FormControlLabel value="transfert" control={<Radio size="small"/>}
                                                      label="Transfert"/>
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        { licence.type && licence.type === 'transfert' ? (
                            <Grid item xs={12}>
                                <TextField
                                    label="Club de départ"
                                    variant="outlined"
                                    size="small"
                                    value={licence.transfertClub}
                                    onChange={(e) => setLicence({...licence, transfertClub: e.target.value})}
                                />
                            </Grid>
                        ) : null }
                        <Grid item xs={12}>
                            <TextField
                                label="Identifiant unique fédéral (IUF)"
                                variant="outlined"
                                size="small"
                                disabled={licence.profile.federalId}
                                value={licence.profile.federalId ? selectedProfile.federalId : licence.federalId}
                                onChange={(e) => setLicence({...licence, federalId: e.target.value})}
                            />
                        </Grid>
                    </Grid>
                    <div className='view-subtitle user'>
                        <h2>Informations personnelles du licencié</h2>
                    </div>
                    <Grid container alignItems="center" spacing={2} className="margin-bottom-15">
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Nom"
                                variant="outlined"
                                size="small"
                                disabled
                                value={licence.profile.lastName}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Prénom"
                                variant="outlined"
                                size="small"
                                disabled
                                value={licence.profile.firstName}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Nationalité"
                                variant="outlined"
                                size="small"
                                error={error && error.missingFields && error.missingFields.includes('nationality')}
                                value={licence.profile.nationality || licence.nationality}
                                disabled={licence.profile.nationality}
                                onChange={(e) => setLicence({...licence, nationality: e.target.value})}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Sexe"
                                variant="outlined"
                                size="small"
                                disabled
                                value={licence.profile.civility === 'miss' ? 'Femme' : 'Homme'}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <DateField
                                label="Date de naissance"
                                variant="outlined"
                                size="small"
                                disabled={licence.profile.birthDate}
                                onChange={(newDate) => setLicence({...licence, birthDate: newDate})}
                                value={dayjs(licence.profile.birthDate || licence.birthDate)}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Téléphone"
                                variant="outlined"
                                size="small"
                                disabled={licence.profile.phone}
                                value={licence.profile.phone}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Adresse"
                                variant="outlined"
                                size="small"
                                disabled={licence.profile.address}
                                value={licence.profile.address}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Code postal"
                                variant="outlined"
                                size="small"
                                disabled={licence.profile.postalCode}
                                value={licence.profile.postalCode}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Ville"
                                variant="outlined"
                                size="small"
                                disabled={licence.profile.city}
                                value={licence.profile.city}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Email"
                                variant="outlined"
                                size="small"
                                disabled
                                value={customer.email}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <div className={`training-selector-block no-margin-bottom ${licence.profile.disability !== null ? 'disabled' : ''}`}>
                                <label className="checkbox-container">
                                    <input type="checkbox"
                                           checked={licence.profile.disability !== null ? licence.profile.disability : licence.disabled}
                                           disabled={licence.profile.disability !== null}
                                           onClick={(e) => setLicence({...licence, disabled: e.target.checked})}
                                    />
                                    <span className="checkmark"/>
                                </label>
                                <span className='small-note'>Je suis athlète handisport</span>
                            </div>
                            <div className='training-selector-block no-margin-bottom'>
                                <label className="checkbox-container">
                                    <input type="checkbox" checked={licence.ads}
                                           onClick={(e) => setLicence({...licence, ads: e.target.checked})}/>
                                    <span className="checkmark"/>
                                </label>
                                <span className='small-note'>Je souhaite recevoir les communications de la FFN</span>
                                <Tooltip enterTouchDelay={0} leaveTouchDelay={5000} placement='top' id='options-tooltip' title={
                                    <React.Fragment>
                                        <CustomRichText
                                            value="Je souhaite recevoir les e-mailings d'information de la FFN, Ligue, Comité et les e-mailings d'offres promotionnelles de la FFN et de ses partenaires"
                                            readMode className="padding"/>
                                    </React.Fragment>
                                }>
                                    <IconButton aria-label="delete" size="small">
                                        <HelpIcon fontSize="small"/>
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        { isUser ? (
                            <Grid item xs={12}>
                                <div className='support-group-type-chooser margin-bottom-15'>
                                    <div className='view-subtitle flex-start'>
                                        <h2>Type de licence</h2>
                                    </div>
                                    <span class="licence-sign-note margin-bottom-15">Si vous êtes entraineur ou officiel, cochez la discipline concernée.</span>
                                    <Grid container spacing={2}>
                                        { constants.supportGroupTypes.map((groupType) => (
                                            <Grid item xs={12} md={6} key={groupType.value}>
                                                <div className='view-subtitle group'>
                                                    <h2>{groupType.label}</h2>
                                                </div>
                                                { groupType.groups.map((group) => (
                                                    <div className="training-selector-block margin-top no-margin-bottom" key={group.value}>
                                                        <label className="checkbox-container">
                                                            <input
                                                                type="checkbox"
                                                                checked={licence.supportGroups.includes(group.value)}
                                                                onChange={() => handleCheckboxChange(group.value)}
                                                            />
                                                            <span className="checkmark"/>
                                                        </label>
                                                        <span className='small-note'>{group.label}</span>
                                                    </div>
                                                ))}
                                            </Grid>
                                        ))}
                                    </Grid>
                                </div>
                                <div className='view-subtitle check flex-start'>
                                    <h2>Contrôle d'honorabilité</h2>
                                    <Tooltip enterTouchDelay={0} leaveTouchDelay={5000} placement='top' id='options-tooltip' title={
                                        <React.Fragment>
                                            <CustomRichText
                                                value="La licence me permet d'exercer comme éducateur sportif, exploitant d'établissement ou arbitre, selon les articles L.212-1, L.322-1 et L.223-1 du code du sport. Mes données seront transmises pour un contrôle d'honorabilité (article L.212-9). En cas de condamnation incompatible, je serai notifié et devrai quitter mes fonctions, sous peine de sanctions pénales (articles L.212-10 et L.322-4)."
                                                readMode className="padding"/>
                                        </React.Fragment>
                                    }>
                                        <IconButton aria-label="delete" size="small">
                                            <HelpIcon fontSize="small"/>
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <IntegrityCheck
                                    licence={licence}
                                    setLicence={setLicence}
                                    error={error}
                                />

                            </Grid>
                        ) : null}
                        <Grid item xs={12}>
                            <div
                                className={`view-subtitle health ${error && error.missingFields && error.missingFields.includes('healthForm') ? 'error' : ''}`}>
                                <h2>Questionnaire Santé</h2>
                            </div>
                            <span className='licence-sign-note margin-bottom-15'>Ce questionnaire détermine la nécessité d'un certificat médical à fournir au club.</span>
                            <HealthForm
                                licence={licence}
                                setLicence={setLicence}
                            />
                            {licence.healthForm && Object.values(licence.healthForm).includes(true) ? (
                                <span className='licence-sign-note warning margin-top'>Attention, vous avez répondu oui à une ou plusieurs questions. Vous devez donc fournir au club un certificat médical d'absence de contre indication à la pratique sportive.</span>
                            ) : null}
                        </Grid>
                        {dayjs().diff(dayjs(licence.profile.birthDate), 'year') < 18 && (
                            <Grid item xs={12}>
                                <div
                                    className={`view-subtitle doping flex-start ${error && error.missingFields && error.missingFields.includes('doping') ? 'error' : ''}`}>
                                    <h2>Contrôle antidopage</h2>
                                    <Tooltip enterTouchDelay={0} leaveTouchDelay={5000} placement='top' id='options-tooltip' title={
                                        <React.Fragment>
                                            <CustomRichText
                                                value="En application de l'article R.232-52 du code du sport, j'autorise tout prélèvement nécessitant une technique invasive (prise de sang, prélèvement de phanères) lors d'un contrôle antidopage sur l'enfant mineur. Dans le cas contraire, je reconnais être informé que l'absence d'autorisation parentale est constitutive d'un refus de se soumettre à ce contrôle et est susceptible d'entrainer des sanctions disciplinaires à son égard."
                                                readMode className="padding"/>
                                        </React.Fragment>
                                    }>
                                        <IconButton aria-label="delete" size="small">
                                            <HelpIcon fontSize="small"/>
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <FormControl>
                                    <RadioGroup name="radio-buttons-group" onChange={(e) => setLicence({
                                        ...licence,
                                        doping: e.target.value === "true"
                                    })}>
                                        <FormControlLabel value="true" control={<Radio size="small"/>}
                                                          label={<span><strong>Oui</strong>, j'autorise tout prélèvement nécessitant une technique invasive (ex: prise de sang)</span>}/>
                                        <FormControlLabel value="false" control={<Radio size="small"/>}
                                                          label={<span><strong>Non</strong>, et reconnais être informé que cela constitue un refus de se soumettre au contrôle</span>}/>
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <div
                                className={`view-subtitle warranty flex-start ${error && error.missingFields && error.missingFields.includes('basicWarranty') ? 'error' : ''}`}>
                                <h2>Garantie de base "individuelle accident"</h2>
                                <Tooltip enterTouchDelay={0} leaveTouchDelay={5000} placement='top' id='options-tooltip' title={
                                    <React.Fragment>
                                        <CustomRichText
                                            value="La garantie Individuelle Accident et Assistance rapatriement est <strong>comprise</strong> dans la licence FFN. En cas de refus, le coût remboursé est de 0,15€ TTC. Dans ce cas, envoyer une copie de ce formulaire auprès de la FFN."
                                            readMode className="padding"/>
                                    </React.Fragment>
                                }>
                                    <IconButton aria-label="delete" size="small">
                                        <HelpIcon fontSize="small"/>
                                    </IconButton>
                                </Tooltip>
                            </div>
                            <span className='licence-sign-note margin-bottom-15'>Cette garantie est comprise avec la licence et totalement gratuite.</span>
                            <FormControl>
                                <RadioGroup
                                    name="radio-buttons-group"
                                    onChange={(e) => setLicence({
                                        ...licence,
                                        basicWarranty: e.target.value === 'true'
                                    })}
                                >
                                    <FormControlLabel value={true} control={<Radio size="small"/>}
                                                      label={<span><strong>Oui</strong>, je souhaite bénéficier de la garantie <strong>comprise</strong> dans la licence FFN</span>}/>
                                    <FormControlLabel value={false} control={<Radio size="small"/>}
                                                      label={<span><strong>Non</strong>, je renonce à bénéficier de la garantie</span>}/>
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <div
                                className={`view-subtitle warranty flex-start ${error && error.missingFields && error.missingFields.includes('complementaryWarranty') ? 'error' : ''}`}>
                                <h2>Garantie complémentaire</h2>
                                <Tooltip enterTouchDelay={0} leaveTouchDelay={5000} placement='top' id='options-tooltip' title={
                                    <React.Fragment>
                                        <CustomRichText
                                            value="Si vous souhaitez souscrire une garantie complémentaire : remplir le formulaire de souscription disponible auprès du club et le renvoyer à l'assureur en joignant un chèque à l'ordre de celui-ci."
                                            readMode className="padding"/>
                                    </React.Fragment>
                                }>
                                    <IconButton aria-label="delete" size="small">
                                        <HelpIcon fontSize="small"/>
                                    </IconButton>
                                </Tooltip>
                            </div>
                            <FormControl>
                                <RadioGroup
                                    name="radio-buttons-group"
                                    onChange={(e) => setLicence({
                                        ...licence,
                                        complementaryWarranty: e.target.value === "true"
                                    })}
                                >
                                    <FormControlLabel value="true" control={<Radio size="small"/>}
                                                      label={<span><strong>Oui</strong>, je souhaite souscrire une option complémentaire</span>}/>
                                    <FormControlLabel value="false" control={<Radio size="small"/>}
                                                      label={<span><strong>Non</strong>, je ne désire pas souscrire d'option complémentaire</span>}/>
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <div className='view-subtitle sign no-margin-bottom'>
                                <h2>Signature</h2>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Fait à (ville)"
                                variant="outlined"
                                size="small"
                                error={error && error.missingFields && error.missingFields.includes('doneIn')}
                                value={licence.doneIn}
                                onChange={(e) => setLicence({...licence, doneIn: e.target.value})}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <DateField
                                label="Date de la signature"
                                variant="outlined"
                                size="small"
                                disabled
                                format="DD/MM/YYYY"
                                value={dayjs()}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <div
                                className={`signature-container ${error && error.missingFields && error.missingFields.includes('signatureSize') ? 'error' : ''}`}>
                                <div className="erase-signature">
                                    <Button variant="contained" size="small" onClick={resetPad}>Effacer</Button>
                                </div>
                                <SignatureCanvas
                                    canvasProps={{width: 419, height: 150, className: 'sigCanvas'}}
                                    ref={(ref) => {
                                        sigPad = ref
                                    }}
                                    onEnd={() => setLicence({
                                        ...licence,
                                        signature: sigPad.toDataURL(),
                                        signatureSize: sigPad.toData().reduce((acc, array) => acc + array.length, 0)
                                    })}
                                />
                            </div>
                        </Grid>
                        {error ? (
                            <Grid item xs={12}>
                                <div className='error-message' ref={errorMessageRef}>
                                    <span>{error.message ? error.message : 'Une erreur est survenue'}</span>
                                    {error.details && error.details.map((detail, index) => (
                                        <span key={index}>- {detail}</span>
                                    ))}
                                </div>
                            </Grid>
                        ) : null}
                    </Grid>
                    <div className='licence-sign-form-buttons'>
                        <button className='main-button without-icon full-width' onClick={() => setOpen(false)}>Annuler</button>
                        <button className='main-button blue-button full-width without-icon' disabled={buttonLoading} onClick={handleValidate}>{!buttonLoading ? 'Vérifier et signer' : <CircularProgress size={20} className="white-loader"/>}</button>
                    </div>
                </div>
            </div>
            <div className={`licence-sign-verification-container ${verification ? 'open' : ''}`}>
                <span className='licence-sign-note warning'>Merci de vérifier soigneusement les informations renseignées.</span>
                <div className="licence-sign-verification-buttons">
                    <button className='main-button without-icon full-width' onClick={() => setVerification(false)}>Annuler</button>
                    <button className='main-button blue-button full-width without-icon' disabled={buttonLoading} onClick={handleSign}>{!buttonLoading ? 'Signer' : <CircularProgress size={20} className="white-loader"/>}</button>
                </div>
            </div>
        </div>
    );
}

export default LicenceSign;