import {Link} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import AuthContext from "../../../contexts/AuthContext";
import {CircularProgress, Grid} from "@mui/material";
import GroupIcon from "./GroupIcon";
import CustomTag from "../../custom/CustomTag";

const GroupList = () => {

    const [groups, setGroups] = useState();
    const [isLoaded, setIsLoaded] = useState(false);
    const { token } = useContext(AuthContext);

    useEffect(() => {
        setIsLoaded(false);
        axios.get(process.env.REACT_APP_ADMIN_API_URL + 'groups', { headers: { Authorization: 'Bearer ' + token }})
            .then((response) => {
                if(response.data) {
                    setGroups(response.data);
                    setIsLoaded(true);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, [token]);

    return (
        <div>
            <div className='view-header groups'>
                <div className='view-title'>
                    <h1>Groupes</h1>
                </div>
                <div className='view-buttons'>
                    <Link to={'/admin/groups/new'}>
                        <button className='main-button add'>Ajouter</button>
                    </Link>
                </div>
            </div>
            <div className="view-container scrollable">
                { isLoaded ? (
                    <Grid container spacing={2}>
                        { groups.map(group => (
                            <Grid item xs={6} key={group.id}>
                                <Link to={'/admin/groups/' + group.id}>
                                    <div className='mobile-group-item'>
                                        <GroupIcon width={16} color={group.color}/>
                                        <h2>{group.name}</h2>
                                        <CustomTag value={group.trainings.length + ' séance(s)'} />
                                    </div>
                                </Link>
                            </Grid>
                        ))}
                    </Grid>
                ) : <div className='loader-container'><CircularProgress size={30}/></div>}
            </div>
        </div>
    );
}

export default GroupList;
