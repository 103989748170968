import React from "react";
import {Accordion, AccordionDetails, AccordionSummary, Grid, Tooltip} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TrainingName from "../trainings/TrainingName";
import CustomList from "../../custom/CustomList";
import columns from "../../../assets/utils/columns";
import arrow_right from "../../../assets/icons/arrow-right--blue.svg";
import {gaugeClasses, Gauge} from '@mui/x-charts/Gauge';
import constants from "../../../assets/utils/constants";
import dayjs from "dayjs";

const GroupHome = ({ group }) => {

    const groupWaitingSubscriptions = React.useMemo(() => columns.groupWaitingSubscriptions, []);

    const formatAmount = (amount, digits = 0) => {
        return Number(amount).toLocaleString('fr-FR', { maximumFractionDigits: digits, minimumFractionDigits: digits });
    }

    return (
        <div className='group-home-container'>
            <div className='view-container scrollable'>
                <div className='dashboard-group-container'>
                    { group.trainingsType === 'optional' ? (
                        <Grid container spacing={2}>
                            <Grid item xs={6} md={3}>
                                <div className='data-card-container folder'>
                                    <h1>{group.submittedSubscriptions}</h1>
                                    <small>Dossiers déposés</small>
                                </div>
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <div className='data-card-container percentage'>
                                    <h1>{group.completionPercentage} %</h1>
                                    <small>Remplissage du groupe</small>
                                </div>
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <div className='data-card-container amount'>
                                    <h1>{formatAmount(group.amountPaidWithFunded, 2)} €</h1>
                                    <small>Montant reçu</small>
                                </div>
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <div className='data-card-container subscription'>
                                    <h1>{formatAmount(group.averageBasketWithFunded, 2)} €</h1>
                                    <small>Panier moyen</small>
                                </div>
                            </Grid>
                            { group.trainings.map((training, index) => (
                                <Grid item xs={12} md={6} key={index}>
                                    <div className='data-large-card-container'>
                                        <div className='data-large-card-first-row'>
                                            <div className='data-group-info'>
                                                <div className='data-group-info-block calendar'>
                                                    <small>{constants.weekDays.find(d => training.weekDay === d.value).label}</small>
                                                    <div className='data-hours-block'>
                                                        <span>{dayjs(`${dayjs().format('YYYY-MM-DD')} ${training.startHour}`).format('HH:mm')}</span>
                                                        <img alt='arrow-right' src={arrow_right} width={12}/>
                                                        <span>{dayjs(`${dayjs().format('YYYY-MM-DD')} ${training.stopHour}`).format('HH:mm')}</span>
                                                    </div>
                                                    <small>{training.pool.name}</small>
                                                </div>
                                                <div className='data-group-info-block amount'>
                                                    <small>Montant reçu</small>
                                                    <h2>{formatAmount(training.amountPaidWithFunded, 2)} €</h2>
                                                </div>
                                            </div>
                                            <div className='data-doughnut-chart'>
                                                <Gauge
                                                    value={training.acceptedSubscriptions}
                                                    valueMax={training.places}
                                                    cornerRadius="50%"
                                                    text={null}
                                                    sx={() => ({[`& .${gaugeClasses.valueArc}`]: { fill: training.gaugeColor }})}
                                                >
                                                </Gauge>
                                                <div className='data-doughnut-capacity'>
                                                    <h2>{training.acceptedSubscriptions}</h2>
                                                    <span>sur {training.places}</span>
                                                </div>
                                            </div>
                                        </div>
                                        { training.pendingSubscriptions > 0 ? (
                                            <div className='data-large-card-second-row'>
                                                <div className='data-status-repartition'>
                                                    { training.waitingValidationCount > 0 ? (
                                                        <Tooltip enterTouchDelay={0} leaveTouchDelay={5000} title="En attente de validation" placement="top">
                                                            <div className='data-status-repartition-block validation' style={{ width: `${(training.waitingValidationCount / training.pendingSubscriptions) * 100}%` }}>
                                                                <span>{training.waitingValidationCount}</span>
                                                            </div>
                                                        </Tooltip>
                                                    ) : null }
                                                    { training.waitingTestCount > 0 ? (
                                                        <Tooltip enterTouchDelay={0} leaveTouchDelay={5000} title="En attente de test" placement="top">
                                                            <div className='data-status-repartition-block test' style={{ width: `${(training.waitingTestCount / training.pendingSubscriptions) * 100}%` }}>
                                                                <span>{training.waitingTestCount}</span>
                                                            </div>
                                                        </Tooltip>
                                                    ) : null }
                                                    { training.waitingPaymentCount > 0 ? (
                                                        <Tooltip enterTouchDelay={0} leaveTouchDelay={5000} title="En attente de paiement" placement="top">
                                                            <div className='data-status-repartition-block payment' style={{ width: `${(training.waitingPaymentCount / training.pendingSubscriptions) * 100}%` }}>
                                                                <span>{training.waitingPaymentCount}</span>
                                                            </div>
                                                        </Tooltip>
                                                    ) : null }
                                                    { training.changeNeededCount > 0 ? (
                                                        <Tooltip enterTouchDelay={0} leaveTouchDelay={5000} title="En attente de changement" placement="top">
                                                            <div className='data-status-repartition-block change' style={{ width: `${(training.changeNeededCount / training.pendingSubscriptions) * 100}%` }}>
                                                                <span>{training.changeNeededCount}</span>
                                                            </div>
                                                        </Tooltip>
                                                    ) : null }
                                                </div>
                                            </div>
                                        ) : null }
                                    </div>
                                </Grid>
                            ))}
                        </Grid>
                    ) : (
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <div className='data-card-container folder'>
                                            <h1>{group.subscriptions.length}</h1>
                                            <small>Dossiers déposés</small>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className='data-card-container percentage'>
                                            <h1>{group.completionPercentage} %</h1>
                                            <small>Remplissage du groupe</small>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className='data-card-container amount'>
                                            <h1>{formatAmount(group.amountPaidWithFunded, 2)} €</h1>
                                            <small>Montant reçu</small>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className='data-card-container subscription'>
                                            <h1>{formatAmount(group.averageBasketWithFunded, 2)} €</h1>
                                            <small>Panier moyen</small>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className='data-large-card-container mandatory'>
                                    <div className='data-large-card-first-row'>
                                        <div className='data-doughnut-chart'>
                                            <Gauge
                                                value={group.acceptedSubscriptions}
                                                valueMax={group.places}
                                                cornerRadius="50%"
                                                innerRadius="85%"
                                                outerRadius="100%"
                                                text={null}
                                                sx={() => ({[`& .${gaugeClasses.valueArc}`]: { fill: group.gaugeColor }})}
                                            />
                                            <div className='data-doughnut-capacity'>
                                                <h2>{group.acceptedSubscriptions}</h2>
                                                <span>sur {group.places}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='data-large-card-second-row'>
                                        <div className='data-status-repartition'>
                                            { group.waitingValidationCount > 0 ? (
                                                <Tooltip enterTouchDelay={0} leaveTouchDelay={5000} title="En attente de validation" placement="top">
                                                    <div className='data-status-repartition-block validation' style={{ width: `${(group.waitingValidationCount / group.pendingSubscriptions) * 100}%` }}>
                                                        <span>{group.waitingValidationCount}</span>
                                                    </div>
                                                </Tooltip>
                                            ) : null }
                                            { group.waitingTestCount > 0 ? (
                                                <Tooltip enterTouchDelay={0} leaveTouchDelay={5000} title="En attente de test" placement="top">
                                                    <div className='data-status-repartition-block validation' style={{ width: `${(group.waitingTestCount / group.pendingSubscriptions) * 100}%` }}>
                                                        <span>{group.waitingTestCount}</span>
                                                    </div>
                                                </Tooltip>
                                            ) : null }
                                            { group.waitingPaymentCount > 0 ? (
                                                <Tooltip enterTouchDelay={0} leaveTouchDelay={5000} title="En attente de paiement" placement="top">
                                                    <div className='data-status-repartition-block validation' style={{ width: `${(group.waitingPaymentCount / group.pendingSubscriptions) * 100}%` }}>
                                                        <span>{group.waitingPaymentCount}</span>
                                                    </div>
                                                </Tooltip>
                                            ) : null }
                                            { group.changeNeededCount > 0 ? (
                                                <Tooltip enterTouchDelay={0} leaveTouchDelay={5000} title="En attente de changement" placement="top">
                                                    <div className='data-status-repartition-block validation' style={{ width: `${(group.changeNeededCount / group.pendingSubscriptions) * 100}%` }}>
                                                        <span>{group.changeNeededCount}</span>
                                                    </div>
                                                </Tooltip>
                                            ) : null }
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    )}
                </div>
                {group.trainingsType === 'optional' ? (
                    <>
                        <div className='view-subtitle user'>
                            <h2>Adhérents par séance</h2>
                        </div>
                        <div className='accordion-container'>
                            {group.trainings.map((training, index) => (
                                <Accordion key={index} disableGutters elevation={0}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel1a-content"
                                                      id="panel1a-header">
                                        <TrainingName group={group} training={training}/>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <CustomList
                                            columns={groupWaitingSubscriptions}
                                            intermediatePath={'/admin/subscriptions'}
                                            entity={group.subscriptions.filter(sub => sub.status === "accepted" && sub.trainings.some(t => t.trainingId === training.id))}
                                            isSelectable={false}
                                            isPaginable={group.subscriptions.filter(sub => sub.status === "accepted" && sub.trainings.some(t => t.trainingId === training.id)).length > 20}
                                        />
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </div>
                    </>
                ) : (
                    <>
                        <div className='view-subtitle user'>
                            <h2>Adhérents</h2>
                        </div>
                        <CustomList
                            columns={groupWaitingSubscriptions}
                            intermediatePath={'/admin/subscriptions'}
                            entity={group.subscriptions.filter(sub => sub.status === "accepted")}
                            isSelectable={false}
                            isPaginable={group.subscriptions.filter(sub => sub.status === "accepted").length > 20}
                        />
                    </>
                )}
            </div>
        </div>
    )
}

export default GroupHome;