import React, {useState, useEffect, useContext} from 'react';
import axios from 'axios';
import {CircularProgress, Grid, IconButton, Tooltip} from '@mui/material';
import AuthContext from "../../../contexts/AuthContext";
import dayjs from "dayjs";
import GroupIcon from "../groups/GroupIcon";
import CustomWarningModal from "../../custom/CustomWarningModal";
import {toast} from "react-toastify";
import toastOptions from "../../../assets/utils/toast";
import EventEdit from "./EventEdit";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import {Link} from "react-router-dom";
import CustomTag from "../../custom/CustomTag";
import CloseIcon from "@mui/icons-material/Close";

const EventDetails = ({ eventData, open, setOpen, planning, setPlanning, groups }) => {

    const { club, user, token } = useContext(AuthContext);
    const [event, setEvent] = useState(null);
    const [subscriptions, setSubscriptions] = useState([]);
    const [modalWarningOpen, setModalWarningOpen] = useState(false);
    const [modalButtonLoading, setModalButtonLoading] = useState(false);
    const [modalAttendanceWarningOpen, setModalAttendanceWarningOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [attendance, setAttendance] = useState([]);
    const [attendanceEditing, setAttendanceEditing] = useState(false);
    const [attendanceSaved, setAttendanceSaved] = useState(false);

    useEffect(() => {
        if(eventData) {
            axios.get(process.env.REACT_APP_ADMIN_API_URL + 'planning/events/' + eventData.id, { headers: { Authorization: 'Bearer ' + token }})
                .then((response) => {
                    const subscriptions = response.data.training.group.trainingsType === 'mandatory' ? response.data.training.group.subscriptions : response.data.training.group.subscriptions.filter(s => s.trainings.some(t => t.trainingId === response.data.training.id));
                    setEvent(response.data);
                    setSubscriptions(subscriptions);
                    setAttendance(response.data.attendance.length > 0 ? response.data.attendance : subscriptions.map(s => ({ profileId: s.profileId, isPresent: true })));
                    setAttendanceEditing(response.data.attendance.length > 0 ? false : user.role === 'club-coach');
                    setAttendanceSaved(response.data.attendance.length > 0);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [eventData, open, modalAttendanceWarningOpen, token]);

    const handleDelete = () => {
        if(eventData) {
            setModalButtonLoading(true);
            setIsEditing(false);
            axios.delete(process.env.REACT_APP_ADMIN_API_URL + 'planning/events/' + event.id, { headers: { Authorization: 'Bearer ' + token }})
                .then(() => {
                    setModalButtonLoading(false);
                    setOpen(false);
                    setModalWarningOpen(false);
                    setPlanning(planning.filter(e => e.id !== event.id));
                    toast.success("L'évènement a bien été supprimé", toastOptions);
                })
                .catch((error) => {
                    console.log(error);
                    setModalButtonLoading(false);
                });
        } else {
            setModalWarningOpen(false);
        }
    }

    const changeAttendance = profileId => {
        setAttendance(attendance.map(a => a.profileId === profileId ? { profileId: a.profileId, isPresent: !a.isPresent } : a));
    }

    const isBirthday = (birthDate, eventDate) => dayjs(birthDate).format('MM-DD') === dayjs(eventDate).format('MM-DD');

    const getAge = (birthDate, eventDate) => dayjs(eventDate).diff(birthDate, 'years');

    const submitAttendance = () => {
        setAttendanceEditing(false);
        axios.post(process.env.REACT_APP_ADMIN_API_URL + 'planning/events/' + event.id + '/attendance', attendance, { headers: { Authorization: 'Bearer ' + token }})
            .then(() => {
                setAttendanceSaved(true);
                toast.success("L'appel a bien été enregistré", toastOptions);
            })
            .catch((error) => {
                toast.error("Une erreur est survenue", toastOptions);
                console.log(error);
            });
    }

    const handleDeleteAttendance = () => {
        setModalButtonLoading(true);
        axios.delete(process.env.REACT_APP_ADMIN_API_URL + 'planning/events/' + event.id + '/attendance', { headers: { Authorization: 'Bearer ' + token }})
            .then(() => {
                setModalButtonLoading(false);
                setModalAttendanceWarningOpen(false);
                toast.success("L'appel a bien été supprimé", toastOptions);
            })
            .catch((error) => {
                console.log(error);
                setModalButtonLoading(false);
            });
    }

    return (
        <div className={`training-details-container ${isEditing ? 'edit-event' : ''} ${open ? 'open' : 'closed'}`}>
            { event ? (
                <>
                    { !isEditing ? (
                        <div className='full-width'>
                            <div className='training-details-content'>
                                <div className='training-details-header'>
                                    <div className='flex-start'>
                                        <GroupIcon width={20} color={event.training.group.color}/>
                                        <div>
                                            <h1>{event.training.group.name}</h1>
                                            <span className='training-details-datetime'>{dayjs(event.start).format('dddd DD MMMM')} • {dayjs(event.start).format('HH:mm')} à {dayjs(event.end).format('HH:mm')}</span>
                                        </div>
                                    </div>
                                    <div className='training-details-buttons'>
                                        <div>
                                            <Tooltip enterTouchDelay={0} leaveTouchDelay={5000} leaveDelay={800} placement="bottom-end" id='options-tooltip' title={
                                                <React.Fragment>
                                                    <div className='tooltip-container'>
                                                        <a href={`mailto:${subscriptions.map(s => s.profile.customer.email).join(',')}`} className='send'>Contacter ({subscriptions.length})</a>
                                                        <span className='edit' onClick={() => setIsEditing(true)}>Modifier</span>
                                                        <span className='delete' onClick={() => setModalWarningOpen(true)}>Supprimer</span>
                                                    </div>
                                                </React.Fragment>
                                            }>
                                                <IconButton aria-label="delete" size="small">
                                                    <MoreVertRoundedIcon fontSize="small"/>
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                        <IconButton aria-label="delete" size="small" onClick={() => setOpen(false)}>
                                            <CloseIcon fontSize="small"/>
                                        </IconButton>
                                    </div>
                                </div>
                                <div className='training-details-info location'>
                                    <span>{club.pools.find(p => p.id === event.poolId).name}</span>
                                </div>
                                {subscriptions.length > 0 ? (
                                    <div className='attendance-edit-block'>
                                        {!attendanceEditing ? (
                                            <>
                                                <div className='training-details-info users'>
                                                    <span>Adhérents ({subscriptions.length})</span>
                                                    {attendanceSaved ? (
                                                        <Tooltip enterTouchDelay={0} leaveTouchDelay={5000} leaveDelay={800} placement="bottom-end" id='options-tooltip' title={
                                                            <React.Fragment>
                                                                <div className='tooltip-container'>
                                                                    <span className='edit' onClick={() => setAttendanceEditing(true)}>Modifier l'appel</span>
                                                                    <span className='delete' onClick={() => setModalAttendanceWarningOpen(true)}>Supprimer l'appel</span>
                                                                </div>
                                                            </React.Fragment>
                                                        }>
                                                            <IconButton aria-label="delete" size="small">
                                                                <MoreVertRoundedIcon fontSize="small"/>
                                                            </IconButton>
                                                        </Tooltip>
                                                    ) : (
                                                        <button className='small-button attendance' onClick={() => setAttendanceEditing(true)}>Faire l'appel</button>
                                                    )}
                                                </div>
                                                <div className='margin-bottom-15'>
                                                    <Grid container spacing={1}>
                                                        { subscriptions.map((subscription, index) => (
                                                            <Grid item xs={12} md={6} key={index}>
                                                                <div className='attendance-line'>
                                                                    <Link to={`/admin/subscriptions/${subscription.id}`} className='attendance-line-name'>
                                                                        <div className={`user-default-picture ${isBirthday(subscription.profile.birthDate, event.start) ? 'birthday' : ''}`}>{ isBirthday(subscription.profile.birthDate, event.start) ? '' : `${subscription.profile.firstName.charAt(0).toUpperCase()}${subscription.profile.lastName.charAt(0).toUpperCase()}`}</div>
                                                                        <span>{`${subscription.profile.firstName.toLowerCase()} ${subscription.profile.lastName.charAt(0).toUpperCase()}. ${isBirthday(subscription.profile.birthDate, event.start) ? `(${getAge(subscription.profile.birthDate, event.start)})` : ''}`}</span>
                                                                    </Link>
                                                                    { attendanceSaved ? (
                                                                        <div className={`attendance-badge ${attendance.find(a => a.profileId === subscription.profileId).isPresent ? "present" : "absent"}`} />
                                                                    ) : null }
                                                                </div>
                                                            </Grid>
                                                        ))}
                                                    </Grid>
                                                </div>
                                            </>
                                        ) : (
                                            <div className='editing'>
                                                <div className='training-details-info attendance'>
                                                    <span>Présence</span>
                                                    <button className='small-button without-icon' onClick={() => setAttendanceEditing(false)}>Annuler</button>
                                                </div>
                                                { subscriptions.map((subscription, index) => (
                                                    <div className='attendance-line' key={index}>
                                                        <Link to={`/admin/subscriptions/${subscription.id}`} className='attendance-line-name'>
                                                            <div className={`user-default-picture ${isBirthday(subscription.profile.birthDate, event.start) ? 'birthday' : ''}`}>{isBirthday(subscription.profile.birthDate, event.start) ? '' : `${subscription.profile.firstName.charAt(0).toUpperCase()}${subscription.profile.lastName.charAt(0).toUpperCase()}`}</div>
                                                            <span>{`${subscription.profile.firstName.toLowerCase()} ${subscription.profile.lastName.toUpperCase()} ${isBirthday(subscription.profile.birthDate, event.start) ? `(${getAge(subscription.profile.birthDate, event.start)})` : ''}`}</span>
                                                        </Link>
                                                        <div className='attendance-line-buttons'>
                                                            <div className={`attendance-button present ${attendance.find(a => a.profileId === subscription.profileId).isPresent ? 'active' : ''}`} onClick={() => changeAttendance(subscription.profileId)}/>
                                                            <div className={`attendance-button absent ${attendance.find(a => a.profileId === subscription.profileId).isPresent ? '' : 'active'}`} onClick={() => changeAttendance(subscription.profileId)}/>
                                                        </div>
                                                    </div>
                                                ))}
                                                <div className='training-details-confirm'>
                                                    <CustomTag value={attendance.reduce((acc, a) => acc + (a.isPresent ? 1 : 0), 0).toString()} className='accepted' />
                                                    <CustomTag value={attendance.reduce((acc, a) => acc + (a.isPresent ? 0 : 1), 0).toString()} className='refused' />
                                                    <button className='main-button blue-button validate' onClick={submitAttendance}>Valider</button>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                ) : null }
                            </div>
                        </div>
                    ) : (
                        <EventEdit
                            event={event}
                            setOpen={setOpen}
                            setModalWarningOpen={setModalWarningOpen}
                            setIsEditing={setIsEditing}
                            planning={planning}
                            setPlanning={setPlanning}
                            groups={groups}
                        />
                    )}
                    <CustomWarningModal
                        content={`Souhaitez-vous vraiment supprimer cet entrainement ?`}
                        modalOpen={modalWarningOpen}
                        setModalOpen={setModalWarningOpen}
                        buttonLoading={modalButtonLoading}
                        confirm={handleDelete}
                    />
                    <CustomWarningModal
                        content={`Souhaitez-vous vraiment supprimer cet appel ?`}
                        modalOpen={modalAttendanceWarningOpen}
                        setModalOpen={setModalAttendanceWarningOpen}
                        buttonLoading={modalButtonLoading}
                        confirm={handleDeleteAttendance}
                    />
                </>
            ) : (
                <CircularProgress size={30}/>
            )}
        </div>
    );
}

export default EventDetails;