import CustomWarningModal from "../../custom/CustomWarningModal"; // Importer CustomWarningModal
import PaymentRecap from "./PaymentRecap";
import React, {useContext, useRef, useState} from "react";
import {CircularProgress, Grid, TextField} from "@mui/material";
import CustomSearchInput from "../../custom/CustomSearchInput";
import dayjs from "dayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import button from "bootstrap/js/src/button";
import CustomModal from "../../custom/CustomModal";
import axios from "axios";
import AuthContext from "../../../contexts/AuthContext";
import constants from "../../../assets/utils/constants";
import {toast} from "react-toastify";
import toastOptions from "../../../assets/utils/toast";
import {Document, Page} from "react-pdf";
import CustomTag from "../../custom/CustomTag";

const SubscriptionPayment = ({ subscription, group, refresh, setRefresh, tab }) => {

    const containerRef = useRef(null);
    const [modalAddOpen, setModalAddOpen] = React.useState(false);
    const [error, setError] = React.useState();
    const [invoiceLoading, setInvoiceLoading] = useState(false);
    const [buttonLoading, setButtonLoading] = React.useState(null);
    const [pdfData, setPdfData] = useState(null);
    const [warningModalOpen, setWarningModalOpen] = useState(false);
    const [generateModalOpen, setGenerateModalOpen] = useState(false);
    const [warningType, setWarningType] = useState(null);
    const [numPages, setNumPages] = useState(null);
    const [containerWidth, setContainerWidth] = useState(400);
    const { club, token } = useContext(AuthContext);
    const [newPayment, setNewPayment] = React.useState({
        date: dayjs().format('YYYY-MM-DD'),
        amount: subscription.totalAmount,
        subscriptionId: subscription.id
    });

    const onDocumentLoadSuccess = ({ numPages }) => {
        updateWidth();
        setNumPages(numPages);
    }

    const updateWidth = () => {
        if (containerRef.current) setContainerWidth((containerRef.current.offsetWidth - 40) > 761 ? 761 : (containerRef.current.offsetWidth - 40));
    };

    React.useEffect(() => {
        updateWidth();
        window.addEventListener('resize', updateWidth);
        return () => window.removeEventListener('resize', updateWidth);
    }, [tab]);

    const handleCreate = () => {
        setButtonLoading('payment');
        setError(null);
        axios.post(process.env.REACT_APP_ADMIN_API_URL + 'subscriptions/' + subscription.id + '/payments', newPayment, { headers: { Authorization: 'Bearer ' + token }})
            .then(() => {
                setRefresh(refresh + 1);
                setModalAddOpen(false);
                setButtonLoading(null);
                toast.success("Le paiement a bien été ajouté", toastOptions);
            })
            .catch((error) => {
                setError(error.response.data);
                setButtonLoading(null);
            });
    }

    React.useEffect(() => {
        setInvoiceLoading(true);
        axios.get(process.env.REACT_APP_FILES_API_URL + '/subscriptions/' + subscription.id + '/invoice.pdf', { responseType: 'blob' })
            .then(response => {
                const file = new Blob([response.data], { type: 'application/pdf' });
                setPdfData(URL.createObjectURL(file));
                setInvoiceLoading(false);
            })
            .catch(() => {
                setInvoiceLoading(false);
            });
    }, [subscription]);

    const handleUpdateInvoice = (warningType) => {
        setWarningType(warningType);
        setWarningModalOpen(true);
    }

    const updateInvoice = () => {
        setWarningModalOpen(false);
        setButtonLoading(warningType);
        axios.put(process.env.REACT_APP_ADMIN_API_URL + 'subscriptions/' + subscription.id + '/invoice', { status: warningType, club: club }, { headers: { Authorization: 'Bearer ' + token }})
            .then(() => {
                setRefresh(refresh + 1);
                setButtonLoading(null);
                toast.success("La facture a bien été générée", toastOptions);
            })
            .catch((error) => {
                console.log(error);
                toast.error("Une erreur est survenue", toastOptions);
                setButtonLoading(null);
            });
        setWarningType(null);
    }

    const generateInvoice = () => {
        setGenerateModalOpen(false);
        setButtonLoading('invoice');
        axios.post(process.env.REACT_APP_ADMIN_API_URL + 'subscriptions/' + subscription.id + '/invoice', club, { headers: { Authorization: 'Bearer ' + token }})
            .then(() => {
                setRefresh(refresh + 1);
                setButtonLoading(null);
                toast.success("La facture a bien été générée", toastOptions);
            })
            .catch((error) => {
                console.log(error);
                toast.error("Une erreur est survenue", toastOptions);
                setButtonLoading(null);
            });
    }

    return (
        <div className='view-container scrollable'>
            <div className='view-subtitle euro'>
                <h2>Paiements</h2>
                <button className='main-button add' onClick={() => setModalAddOpen(true)}>Ajouter un paiement</button>
            </div>
            <div className='form-info-container'>
                <PaymentRecap
                    isPublic={false}
                    subscription={subscription}
                    refreshSubscription={refresh}
                    setRefreshSubscription={setRefresh}
                    group={group}
                />
            </div>
            <div className='view-subtitle invoice'>
                <div className='flex-view'>
                    <h2 className='margin-right'>Facture</h2>
                    {
                        {
                            'draft': <CustomTag className='draft' value='Brouillon'/>,
                            'sent': <CustomTag value='Envoyée' />,
                            'paid': <CustomTag className='accepted' value='Acquittée' />,
                            'cancelled': <CustomTag className='refused' value='Annulée' />
                        }
                            [subscription.invoiceStatus]
                    }
                </div>
                <div>
                    { subscription.invoiceStatus === 'draft' ? (
                        <button className='main-button add' onClick={() => setGenerateModalOpen(true)}>{buttonLoading === 'invoice' ? <CircularProgress size={20}/> : 'Générer la facture'}</button>
                    ) : (
                        <>
                            <button className='main-button margin-right download' onClick={() => window.open(pdfData)}>Télécharger</button>
                            { subscription.invoiceStatus !== 'paid' && subscription.invoiceStatus !== 'cancelled' ? (
                                <>
                                    <button className='main-button paid margin-right' onClick={() => handleUpdateInvoice('paid')}>{buttonLoading === 'paid' ? <CircularProgress size={20}/> : 'Acquitter'}</button>
                                    <button className='main-button cancel' onClick={() => handleUpdateInvoice('cancelled')}>{buttonLoading === 'cancelled' ? <CircularProgress size={20}/> : 'Annuler'}</button>
                                </>
                            ) : null}
                        </>
                    )}
                </div>
            </div>
            {!invoiceLoading ? (
                <div>
                    {pdfData ? (
                        <div className='invoice-viewer-pdf-container' ref={containerRef}>
                            <Document file={pdfData} onLoadSuccess={onDocumentLoadSuccess} loading={
                                <div className='pdf-loader-container'>
                                    <CircularProgress size={50}/>
                                </div>
                            }>
                                { Array.from(new Array(numPages), (el, index) => (
                                    <Page
                                        key={`page_${index + 1}`}
                                        pageNumber={index + 1}
                                        renderAnnotationLayer={false}
                                        renderTextLayer={false}
                                        width={containerWidth}
                                        loading={
                                            <div className='pdf-loader-container'>
                                                <CircularProgress size={50}/>
                                            </div>
                                        }
                                    />
                                ))}
                            </Document>
                        </div>
                    ) : <CustomTag value="Aucune facture n'a été générée pour le moment." /> }
                </div>
            ) : (
                <div className='pdf-loader-container'>
                    <CircularProgress size={50}/>
                </div>
            )}
            <CustomModal show={modalAddOpen} setShow={setModalAddOpen} title='Ajouter un paiement' className="no-padding">
                <div className='view-subtitle flex-start'>
                    <h2 className='margin-right'>Informations du paiement</h2>
                </div>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            label="Libellé du paiement *"
                            type="text"
                            error={error && error.missingFields && error.missingFields.includes('label')}
                            value={newPayment.label}
                            onChange={(e) => setNewPayment({...newPayment, label: e.target.value})}
                        />
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <CustomSearchInput
                            placeholder="Statut *"
                            entity={constants.paymentStatus}
                            className={'public ' + (error && error.missingFields && error.missingFields.includes('status') ? 'error' : '')}
                            value={constants.paymentStatus.find(s => s.value === newPayment.status)}
                            onChange={(value) => setNewPayment({...newPayment, status: value.value})}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <DatePicker
                            label='Date de paiement *'
                            format='DD/MM/YYYY'
                            value={dayjs(newPayment.date)}
                            onChange={(date) => setNewPayment({
                                ...newPayment,
                                date: dayjs(date).format('YYYY-MM-DD')
                            })}
                        />
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <CustomSearchInput
                            placeholder="Moyen de paiement *"
                            labelType="group"
                            entity="payment-modes"
                            className={'public ' + (error && error.missingFields && error.missingFields.includes('paymentModeId') ? 'error' : '')}
                            value={newPayment.paymentModeId}
                            valueIsId
                            onChange={(value) => setNewPayment({...newPayment, paymentModeId: value ? value.id : null})}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            label="Montant *"
                            type="number"
                            error={error && error.missingFields && error.missingFields.includes('amount')}
                            value={newPayment.amount}
                            onChange={(e) => setNewPayment({...newPayment, amount: e.target.value})}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {error ? (
                            <div className='error-message flex-column align-items-start justify-content-center'>
                                {error.details && error.details.map((detail, index) => (
                                    <span key={index}>- {detail}</span>
                                ))}
                            </div>
                        ) : null}
                    </Grid>
                </Grid>
                <div className='modal-bottom-button'>
                    <button className='main-button without-icon margin-right'
                        onClick={() => setModalAddOpen(false)}>Annuler
                    </button>
                    <button className='main-button blue-button launch' onClick={handleCreate}
                        disabled={buttonLoading === 'payment'}>{buttonLoading === 'payment' ? <CircularProgress className='white-loader' size={20}/> : 'Ajouter le paiement'}</button>
                </div>
            </CustomModal>
            <CustomWarningModal
                modalOpen={warningModalOpen}
                setModalOpen={setWarningModalOpen}
                content={`Vous êtes sur le point d'${warningType === 'paid' ? 'acquitter' : 'annuler'} cette facture. Cette action est irréversible. Êtes-vous sûr de vouloir continuer ?`}
                confirm={updateInvoice}
            />
            <CustomWarningModal
                modalOpen={generateModalOpen}
                setModalOpen={setGenerateModalOpen}
                content={`Vous êtes sur le point de générer une nouvelle facture. Êtes-vous sûr de vouloir continuer ?`}
                confirm={generateInvoice}
            />
        </div>
    );
}

export default SubscriptionPayment;