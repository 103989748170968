import {CircularProgress, Grid, IconButton} from "@mui/material";
import GroupIcon from "../groups/GroupIcon";
import dayjs from "dayjs";
import React, {useContext} from "react";
import CustomSearchInput from "../../custom/CustomSearchInput";
import {TimePicker} from "@mui/x-date-pickers/TimePicker";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import axios from "axios";
import AuthContext from "../../../contexts/AuthContext";
import {toast} from "react-toastify";
import toastOptions from "../../../assets/utils/toast";
import CloseIcon from "@mui/icons-material/Close";

const EventAdd = ({ eventData, open, setOpen, planning, setPlanning, groups }) => {

    const [newEvent, setNewEvent] = React.useState({});
    const [buttonLoading, setButtonLoading] = React.useState(false);
    const { token } = useContext(AuthContext);

    React.useEffect(() => {
        if(eventData) {
            setNewEvent({...newEvent, start: dayjs(eventData.start), end: dayjs(eventData.end) });
        }
    }, [eventData]);

    const handleSave = () => {
        if(isFormValid()) {
            setButtonLoading(true);
            axios.post(process.env.REACT_APP_ADMIN_API_URL + 'planning', {
                ...newEvent,
                start: newEvent.start.format('YYYY-MM-DD HH:mm:ss'),
                end: newEvent.end.format('YYYY-MM-DD HH:mm:ss')
            }, { headers: { Authorization: 'Bearer ' + token }})
                .then(response => {
                    setPlanning([...planning, {
                        ...response.data,
                        title: groups.find(g => g.id === newEvent.groupId).name,
                        color: groups.find(g => g.id === newEvent.groupId).color,
                        active: true
                    }]);
                    setNewEvent({});
                    setButtonLoading(false);
                    setOpen(false);
                    toast.success("L'évènement a bien été ajouté", toastOptions);
                })
                .catch((error) => {
                    console.log(error);
                    toast.error("Une erreur est survenue", toastOptions);
                    setButtonLoading(false);
                });
        }
    }

    const isFormValid = () => {
        return newEvent.start && newEvent.end && newEvent.groupId && newEvent.trainingId && newEvent.poolId;
    }

    return (
        <div className={`training-details-container add-event ${open ? 'open' : 'closed'}`}>
            { eventData ? (
                <div>
                    <div className='training-details-content'>
                        <div className='training-details-header'>
                            <div className='flex-start'>
                                <GroupIcon width={20} color={newEvent.groupId ? groups.find(g => g.id === newEvent.groupId).color : null}/>
                                <h1>Ajouter un évènement</h1>
                            </div>
                            <div className='training-details-buttons'>
                                <IconButton aria-label="delete" size="small" onClick={() => setOpen(false)}>
                                    <CloseIcon fontSize="small"/>
                                </IconButton>
                            </div>
                        </div>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <DatePicker
                                    format='DD/MM/YYYY'
                                    label='Début'
                                    slotProps={{
                                        textField: {
                                            // error: error && error.missingFields.includes('birthDate')
                                            size: 'small',
                                        },
                                    }}
                                    value={newEvent.start}
                                    onChange={(newDate) => setNewEvent({...newEvent, start: dayjs(dayjs(newDate).format('YYYY-MM-DD') + newEvent.start.format('HH:mm:ss')) })}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TimePicker
                                    label="Heure"
                                    ampm={false}
                                    className='hour-picker'
                                    slotProps={{ textField: { size: 'small' }}}
                                    views={['hours', 'minutes']}
                                    value={newEvent.start}
                                    onChange={(date) => setNewEvent({...newEvent, start: dayjs(date) })}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <DatePicker
                                    format='DD/MM/YYYY'
                                    label='Fin'
                                    slotProps={{
                                        textField: {
                                            // error: error && error.missingFields.includes('birthDate')
                                            size: 'small',
                                        },
                                    }}
                                    minDate={newEvent.start}
                                    value={newEvent.end}
                                    onChange={(newDate) => setNewEvent({...newEvent, end: dayjs(dayjs(newDate).format('YYYY-MM-DD') + newEvent.end.format('HH:mm:ss')) })}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TimePicker
                                    label="Heure"
                                    ampm={false}
                                    className='hour-picker'
                                    slotProps={{ textField: { size: 'small' }}}
                                    views={['hours', 'minutes']}
                                    value={newEvent.end}
                                    onChange={(date) => setNewEvent({...newEvent, end: dayjs(date) })}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CustomSearchInput
                                    placeholder='Choisissez un groupe'
                                    entity='groups'
                                    labelType='group'
                                    value={newEvent.groupId}
                                    onChange={(group) => {
                                        setNewEvent({ ...newEvent, groupId: group.id, trainingId: group.trainingsType === 'mandatory' ? group.trainings[0].id : null });
                                    }}
                                    valueIsId
                                />
                            </Grid>
                            { newEvent.groupId && groups.find(g => g.id === newEvent.groupId).trainingsType === 'optional' ? (
                                <Grid item xs={12}>
                                    <CustomSearchInput
                                        placeholder='Choisissez une séance'
                                        entity={groups.find(g => g.id === newEvent.groupId).trainings}
                                        labelType='training'
                                        value={newEvent.trainingId}
                                        onChange={(training) => setNewEvent({ ...newEvent, trainingId: training.id })}
                                        valueIsId
                                    />
                                </Grid>
                            ) : null }
                            <Grid item xs={12}>
                                <CustomSearchInput
                                    placeholder='Choisissez une piscine'
                                    entity='pools'
                                    labelType='pool'
                                    value={newEvent.poolId}
                                    onChange={(pool) => setNewEvent({ ...newEvent, poolId: pool.id })}
                                    valueIsId
                                />
                            </Grid>
                        </Grid>
                        <div className='bottom-buttons'>
                            <button className='main-button without-icon' onClick={() => setOpen(false)}>Annuler</button>
                            <button className='main-button blue-button without-icon' onClick={handleSave} disabled={buttonLoading || !isFormValid()}>{ buttonLoading ? <CircularProgress className='white-loader' size={20} /> : "Enregistrer"}</button>
                        </div>
                    </div>
                </div>
            ) : (
                <CircularProgress size={30}/>
            )}
        </div>
    );
}

export default EventAdd;