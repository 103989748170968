import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import React, {useContext, useState} from "react";
import axios from "axios";
import AuthContext from "../../../contexts/AuthContext";
import {CircularProgress, Grid, IconButton, Tab, Tabs} from "@mui/material";
import ChangeSubscription from "../../public/customers/ChangeSubscription";
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import PaymentRecap from "../../admin/subscriptions/PaymentRecap";
import CustomTag from "../../custom/CustomTag";
import TrainingName from "../../admin/trainings/TrainingName";
import CustomField from "../../custom/CustomField";
import CustomFile from "../../custom/CustomFile";
import SubscriptionChat from "../../admin/subscriptions/SubscriptionChat";
import SubscriptionLicence from "../../admin/subscriptions/SubscriptionLicence";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";

const SubscriptionView = ({ appRefresh, setAppRefresh }) => {

    const [searchParams, setSearchParams] = useSearchParams();
    const [tab, setTab] = useState(searchParams.get('tab') ? parseInt(searchParams.get('tab')) : 0);
    const { subscriptionId } = useParams();
    const [subscription, setSubscription] = useState();
    const [group, setGroup] = useState();
    const [isLoaded, setIsLoaded] = useState(false);
    const { token } = useContext(AuthContext);
    const navigate = useNavigate();

    React.useEffect(() => {
        setIsLoaded(false);
        axios.get(process.env.REACT_APP_PUBLIC_API_URL + 'subscriptions/' + subscriptionId, { headers: { Authorization: 'Bearer ' + token }})
            .then((response) => {
                setSubscription(response.data);
                setGroup(response.data.group);
                setIsLoaded(true);
            })
            .catch((error) => console.log(error));
    }, [token, subscriptionId]);

    return isLoaded ? (
        <div className='subscription-view'>
            <div className='view-header subscriptions'>
                <div className='view-title'>
                    <h1>Votre demande</h1>
                </div>
                <div className='view-buttons'>
                    <IconButton aria-label="delete" size="small" onClick={() => navigate(-1)}>
                        <ArrowBackRoundedIcon fontSize="small"/>
                    </IconButton>
                </div>
            </div>
            <div>
                <Tabs value={tab} onChange={(e, newValue) => {
                    setTab(newValue);
                    setSearchParams({tab: newValue})
                }}>
                    <Tab value={0} label="Informations"/>
                    <Tab value={1} label="Votre licence"/>
                    <Tab value={2} label="Fil de discussion"/>
                </Tabs>
            </div>
            <div role="tabpanel" hidden={tab !== 0}>
                <div className="view-container subscriptions">
                    {subscription.status === 'change-needed' ? (
                        <ChangeSubscription
                            group={group}
                            subscriptionId={subscriptionId}
                            appRefresh={appRefresh}
                            setAppRefresh={setAppRefresh}
                        />
                    ) : (
                        <>
                            {subscription.status === 'waiting-payment' ? (
                                <div className='form-info-container'>
                                    <PaymentRecap
                                        isPublic={true}
                                        subscription={subscription}
                                        group={group}
                                    />
                                </div>
                            ) : null}
                            <div className='subscription-line user'>
                                <span className='subscription-line-title'>Profil </span>
                                <CustomTag
                                    value={`${subscription.profile.firstName} ${subscription.profile.lastName}`}/>
                            </div>
                            <div className='subscription-line status'>
                                <span className='subscription-line-title'>Statut </span>
                                <CustomTag value={subscription.status}/>
                            </div>
                            { subscription.invoiceNb ? (
                                <div className='subscription-line invoice'>
                                    <span className='subscription-line-title'>Facture </span>
                                    <CustomTag value={subscription.invoiceNb}/>
                                    <a href={`${process.env.REACT_APP_FILES_API_URL}subscriptions/${subscription.id}/invoice.pdf`} target='_blank'>
                                        <IconButton aria-label="delete" size="small">
                                            <VisibilityRoundedIcon fontSize="small" />
                                        </IconButton>
                                    </a>
                                </div>
                            ) : null }
                            {group.trainingsType === "optional" ? (
                                <div>
                                    <div className='view-subtitle calendar'>
                                        <h2>Séance(s) choisie(s)</h2>
                                    </div>
                                    <div className='subscription-trainings'>
                                        {subscription.trainings.map((training, index) => (
                                            <TrainingName key={index}
                                                          training={group.trainings.find(groupTraining => training.trainingId === groupTraining.id)}/>
                                        ))}
                                    </div>
                                </div>
                            ) : null}
                            {group.fields.filter(f => f.isPublic).length > 0 ? (
                                <>
                                    <div className='view-subtitle field'>
                                        <h2>Informations complémentaires</h2>
                                    </div>
                                    <div className='form-info-container'>
                                        <Grid container spacing={2}>
                                            {group.fields.filter(f => f.isPublic).map((field, index) => (
                                                <Grid item xs={12} md={6} key={index}>
                                                    <CustomField
                                                        size='small'
                                                        field={field}
                                                        value={subscription.fields.some(f => f.fieldId === field.id) ? subscription.fields.find(f => f.fieldId === field.id).value : null}
                                                        readOnly
                                                    />
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </div>
                                </>
                            ) : null}
                            {group.files.length > 0 ? (
                                <>
                                    <div className='view-subtitle file'>
                                        <h2>Fichiers déposés</h2>
                                    </div>
                                    <div className='form-info-container'>
                                        <Grid container spacing={2}>
                                            {group.files.sort(file => subscription.files.find(f => f.fileId === file.id) ? -1 : 1).map(file => (
                                                <Grid item xs={12} md={4} key={file.id}>
                                                    <CustomFile fileInfo={file}
                                                                selectedFile={subscription.files.find(f => f.fileId === file.id)}
                                                                readOnly/>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </div>
                                </>
                            ) : null}
                            {subscription.status === 'accepted' ? (
                                <div>
                                    <div className='view-subtitle price'>
                                        <h2>Paiement, options et réductions</h2>
                                    </div>
                                    <div className='form-info-container'>
                                        <PaymentRecap
                                            isPublic={true}
                                            subscription={subscription}
                                            group={group}
                                        />
                                    </div>
                                </div>
                            ) : null}
                        </>
                    )}
                </div>
            </div>
            <div role="tabpanel" hidden={tab !== 1}>
                <SubscriptionLicence subscription={subscription} tab={tab}/>
            </div>
            <div role="tabpanel" hidden={tab !== 2}>
                <SubscriptionChat subscription={subscription} isPublic/>
            </div>
        </div>
    ) : <div className='loader-container'><CircularProgress size={30}/></div>;
}

export default SubscriptionView;